<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <form class="form-inline transparent-form p10 border-page m-t-2">
            <div class="col-lg-12 row">
              <div class="col-lg-2" style="max-width: 120px">
                <div class="custom-control custom-radio mb-1">
                  <Field
                    type="radio"
                    value="PENDING"
                    id="gross_partnership"
                    name="net_gross_partner"
                    v-model="type"
                    v-on:change="getList()"
                    class="custom-control-input ng-pristine ng-untouched ng-valid"
                    checked="checked"
                    style="height: unset !important" />
                  <label
                    style="
                      font-size: 13px;
                      padding-top: 5px;
                      justify-content: flex-start;
                    "
                    for="gross_partnership"
                    class="custom-control-label mt-1"
                    >Order Book</label
                  >
                </div>
                <div class="custom-control custom-radio">
                  <Field
                    v-model="type"
                    type="radio"
                    value="EXECUTED"
                    id="gross_partnership2"
                    v-on:change="getList(1)"
                    name="net_gross_partner"
                    class="custom-control-input ng-pristine ng-untouched ng-valid"
                    checked="checked"
                    style="height: unset !important" />
                  <label
                    style="
                      font-size: 13px;
                      padding-top: 5px;
                      justify-content: flex-start;
                    "
                    for="gross_partnership2"
                    class="custom-control-label mt-1"
                    >Trade Book</label
                  >
                </div>
                <span class="text-error"></span>
              </div>
              <div class="col-lg-2 mt-1">
                <Multiselect
                  mode="single"
                  trackBy="new_name"
                  label="new_name"
                  valueProp="id"
                  class="register-select"
                  placeholder=" Client"
                  v-model="user_id"
                  :options="client_list"
                  searchable="true" />
              </div>
              <div class="col-lg-2 mt-1">
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="value"
                  class="register-select"
                  placeholder=" Exchange"
                  v-model="exchange"
                  :options="exchanges"
                  searchable="true" />
              </div>
              <div class="col-lg-1 mt-1 mr-3">
                <datepicker
                  v-model="fromDate"
                  class="form-control"
                  inputFormat="dd-MM-yyyy"
                  placeholder="From Date" />
              </div>
              <div class="col-1"></div>
              <div class="col-lg-1 mt-1 mr-3">
                <datepicker
                  v-model="toDate"
                  class="form-control"
                  inputFormat="dd-MM-yyyy"
                  placeholder="From Date" />
              </div>
              <div class="col-lg-2" style="margin-left: 100px; margin-top: 4px">
                <button
                  id="btn-search"
                  type="button"
                  v-on:click="getList(1)"
                  class="btn btn-primary mb-2">
                  Search
                </button>
                &nbsp;
                <button
                  type="button"
                  v-on:click="reset"
                  class="btn btn-secondary mb-2">
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        class="row"
        style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
        <div class="col-md-12 bg-light border-page">
          <table
            id="table"
            style="margin-top: 16px"
            data-toggle="table"
            data-search="true"
            data-filter-control="true"
            data-toolbar="#toolbar"
            class="table table-sm text-center text-white table-bordered product-list table-hover">
            <thead>
              <tr>
                <th class="head">O/T</th>
                <th
                  class="head sorting"
                  id="id-actions"
                  v-on:click="sorting('action', 'id-actions')">
                  Action
                </th>
                <th
                  class="head sorting"
                  id="id-name"
                  v-on:click="sorting('id', 'id-name')">
                  Group No
                </th>
                <th class="head">Client</th>
                <th
                  class="head sorting"
                  id="id-trading_symbol"
                  v-on:click="sorting('trading_symbol', 'id-trading_symbol')">
                  Script
                </th>
                <th
                  class="head sorting"
                  id="id-order_action"
                  v-on:click="sorting('order_action', 'id-order_action')">
                  Type
                </th>
                <th class="head" id="id-eeee">I/O</th>
                <th
                  class="head sorting"
                  id="id-lot"
                  v-on:click="sorting('lot', 'id-lot')">
                  Lot
                </th>
                <th
                  class="head sorting"
                  id="id-quantity"
                  v-on:click="sorting('quantity', 'id-quantity')">
                  QTY
                </th>
                <th
                  class="head sorting"
                  id="id-price"
                  v-on:click="sorting('price', 'id-price')">
                  Rate
                </th>
                <th
                  class="head sorting"
                  id="id-add_time"
                  v-on:click="sorting('add_time', 'id-add_time')">
                  Add Time
                </th>
                <th class="head">Act User</th>
                <th
                  class="head sorting"
                  id="id-ip"
                  v-on:click="sorting('id', 'id-ip')">
                  IP Address
                </th>
                <th
                  class="head sorting"
                  id="id-created_at"
                  v-on:click="sorting('created_at', 'id-created_at')">
                  Act Time
                </th>
                <th class="head" v-if="type == 'EXECUTED'">Action</th>
              </tr>
            </thead>
            <tbody v-show="!loader">
              <tr v-for="(data, index) in list" :key="index">
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ type == "PENDING" ? "Order" : "Trade" }}
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.action }}
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.id }}
                </td>
                <td
                  style="text-align: left"
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{
                    data.user
                      ? $helperService.getUpperCase(
                          data.user?.name + "-" + data.user?.code
                        )
                      : ""
                  }}
                </td>
                <td
                  style="text-align: left"
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.trading_symbol ? data.trading_symbol : "" }}
                </td>
                <td
                  :class="data.order_action == 'BUY' ? 'body-buy' : 'body-sell'"
                  :style="
                    data.order_action == 'BUY' ? 'color: blue;' : 'color: red;'
                  ">
                  {{ data.order_action }}
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.new_type && data.new_type ? data.new_type : "-" }}
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.lot?.toFixed(2) }}
                </td>

                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.quantity }}
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.price }}
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{
                    data.created_at
                      ? $helperService.getDateTime(data.add_time)
                      : ""
                  }}
                </td>

                <td
                  style="text-align: left"
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{
                    data.updated_by
                      ? $helperService.getUpperCase(
                          data.updated_by?.name + "-" + data.updated_by?.code
                        )
                      : "ADMIN"
                  }}
                </td>
                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{ data.ip }}
                </td>

                <td
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  {{
                    data.created_at
                      ? $helperService.getDateTime(data.created_at)
                      : ""
                  }}
                </td>
                <td
                  v-if="type == 'EXECUTED'"
                  :class="
                    data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                  ">
                  <i
                    v-if="data.action == 'Delete'"
                    class="fa fa-refresh"
                    v-on:click="restoreTrade(data)"
                    style="cursor: pointer; font-size: medium"
                    aria-hidden="true"></i>
                </td>
              </tr>
            </tbody>
          </table>
          <ErrorComponent @retry="getList(1)" ref="errorComponent" />
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination
                  @page-change="pageChange"
                  @items-per-page-change="itemsPerPageChange"
                  ref="listPagination" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      @remove="deleteRecord($event)"
      @get-list="getList(1)"
      ref="deleteItem" />
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import DeleteModal from "@/components/DeleteModal";
import moment from "moment";
import Datepicker from "vue3-datepicker";
import { Field } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import swal from "sweetalert2";
export default {
  components: {
    ErrorComponent,
    Datepicker,
    Field,
    DeleteModal,
    Multiselect,
    Pagination,
  },
  name: "Profile",
  data() {
    return {
      loader: true,
      type: "PENDING",
      list: [],
      fromDate: new Date(),
      count: 0,
      user_id: "",
      exchange: "",
      toDate: new Date(),
      client_list: [],
      exchanges: [
        {
          name: "NSE",
          value: "NFO",
        },
        {
          name: "MCX",
          value: "MCX",
        },{
          name: "COMEX",
          value: "COMEX",
        },
      ],
    };
  },
  mounted() {
    this.checkAccess();
    this.getClientList();
    this.getList(1);
  },
  methods: {
    checkAccess() {
      var access = this.$store.getters.getAccess;
      if (access.is_orderEditLog == 0) {
        this.$router.push("/access-denied");
      }
    },
    getClientList() {
      this.$api
        .getAPI({
          _action: "/client-list",
        })
        .then((res) => {
          this.client_list = [];
          for (var i in res) {
            this.client_list.push({
              ...res[i],
              new_name: res[i].name + " (" + res[i].code + ")",
            });
          }
        })
        .catch(() => {});
    },
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(1);
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(1, obj.order_by, obj.sort_by);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};
      if (this.fromDate) {
        searchObj.from_date = moment(this.fromDate).format("yyyy-MM-DD");
      }

      if (this.toDate) {
        searchObj.to_date = moment(this.toDate).format("yyyy-MM-DD");
      }

      if (this.trade_type) {
        searchObj.trade_action_type = this.trade_type;
      }
      if (this.name) {
        searchObj.name = this.name;
      }
      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      if (this.authority_id) {
        searchObj.authority_id = this.authority_id;
      }
      if (this.broker_id) {
        searchObj.broker_id = this.broker_id;
      }
      if (this.user_id) {
        searchObj.user_id = this.user_id;
      }
      if (this.exchange) {
        searchObj.exchange = this.exchange;
      }
      if (orderBy) {
        searchObj.order_by = orderBy;
      }
      if (sortBy) {
        searchObj.sort_by = sortBy;
      }
      searchObj.trade_status = this.type;
      searchObj.offset = page - 1;
      searchObj.limit = 20;
      this.$api
        .getAPI({
          _action: "/trade-log-pagination-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.count = res.count;
          if (page == 1) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count, 20);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }

          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    reset() {
      this.fromDate = new Date();
      this.toDate = new Date();
      this.type = "";
    },
    getDate(date) {
      return date ? moment(date).format("DD/MM/YYYY hh:mm A") : "-";
    },
    getSrNo(index) {
      return this.$refs.listPagination
        ? (this.$refs.listPagination.currentPage - 1) * 10 + (index + 1)
        : index + 1;
    },
    restoreTrade(data) {
      swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to restore this trade ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            var obj = {
              id: data.id,
              trade_id: data.trade_id,
            };
            this.$api
              .postAPI({
                _action: "/restore-trade",
                _body: obj,
              })
              .then((res) => {
                if (res) {
                  this.$notify({
                    title: "Success",
                    type: "success",
                    text: res.message,
                  });
                }
              })
              .catch(() => {});
          } else {
            this.getList(1);
          }
        });
    },
    showDeletePopup(data) {
      this.$refs.deleteItem.showModal(
        "Delete Broker",
        "Are you sure you want to delete " + data.name,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$refs.deleteItem.deleteRecord("/account/" + detail.id);
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
