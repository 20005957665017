<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <h6 class="text-start mt-2" style="color: rgb(29, 68, 91); font-weight: 600">
            BROKERAGES
          </h6>
        </div>
      </div>

      <div class="row" style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
        <div class="col-md-12 bg-light border-page">
          <div class="col-lg-12 mb-1 row">
            <div class="col-2">
              <Field type="text" class="form-control" name="ledger_balance" placeholder="SEARCH" v-model="keyword"
                v-on:keyup="getList()" />
            </div>
            <div class="col-3 text-left">
              <button id="btn-search" type="button" v-on:click="getList(1)" class="btn btn-primary mb-2">
                Search
              </button>
              &nbsp;
              <button type="button" v-on:click="reset" class="btn btn-secondary mb-2">
                Reset
              </button>
            </div>

            <div class="col-7 text-right"    v-if="role != 'USER' && role != 'BROKER'">
              <button id="btn-Brokerage" v-on:click="$router.push('/add-brokerages')" type="button"
                class="btn btn-primary mb-2 ml-2">
                Add Brokerage
              </button>
            </div>
          </div>
          <div class="col-md-12 bg-light sticky-table-header">
            <table id="table" style="margin-top: 16px" data-toggle="table" data-search="true" data-filter-control="true"
              data-toolbar="#toolbar"
              class="table table-sm text-center text-white table-bordered product-list table-hover">
              <thead>
                <tr>
                  <th class="head">Client Name</th>
                  <th class="head">Account Type</th>
                  <th class="head">Segment</th>
                  <th class="head">Script Wise</th>
                  <th class="head">% Wise</th>
                  <th class="head">Fix</th>
                  <th class="head">Add Time</th>
                  <th class="head">View</th>
                  <th class="head" v-if="role != 'USER' && role != 'BROKER'">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in list" :key="index">
                  <td class="body">
                    {{
                      data.user
                      ? $helperService.getUpperCase(
                        data.user.code + "-" + data.user.name
                      )
                      : ""
                    }}
                  </td>
                  <td class="body"> {{ $helperService.getTitleCase(data.user?.role) }}</td>
                  <td class="body">{{ data.exchange == 'NFO' ? 'NSE' : data.exchange }}-FUT</td>

                  <td class="body">
                    <!-- {{ data.is_script_wise == 1 ? "YES" : "NO" }} -->
                    {{ data.instrument_token != 0 ? data.instrument_token : "NO" }}
                  </td>
                  <td class="body">
                    {{
                      data.brokerage_type == "PERCENT"
                      ? data.delivery_brokerage
                      : "NO"
                    }}
                  </td>
                  <td class="body">
                    {{
                      data.brokerage_type == "FIX"
                      ? data.delivery_brokerage
                      : "NO"
                    }}
                  </td>
                  <!-- <td class="body">-</td> -->
                  <!-- <td class="body">-</td> -->
                  <!-- <td class="body">-</td> -->
                  <td class="body">
                    {{ $helperService.getDateTime(data.updated_at) }}
                  </td>
                  <td class="body">
                    <a style="color: #c97fc7; cursor: pointer" v-on:click="$router.push('/edit-brokerage/' + data.id)">
                      View
                    </a>
                  </td>
                  <td class="body" v-if="role != 'USER' && role != 'BROKER'">
                    <a v-on:click="showDeletePopup(data)" class="fa fa-trash text-danger" style="cursor: pointer"
                      title="Delete"></a>
                  </td>
                </tr>
              </tbody>
            </table>
            <ErrorComponent @retry="getList(0)" ref="errorComponent" />
          </div>
          <div class="row mx-0 mb-4">
            <div class="col-lg-12 mt-5 text-center">
              <div class="page-bottom-pagination text-right">
                <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange" ref="listPagination" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal @remove="deleteRecord($event)" @get-list="getList(0)" ref="deleteItem" />
  </div>
</template>
<script>
import { Field } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import DeleteModal from "@/components/DeleteModal";
import moment from "moment";
import swal from "sweetalert2";
// import Datepicker from "vue3-datepicker";
export default {
  components: {
    ErrorComponent,
    Field,
    // Datepicker,
    DeleteModal,
    Pagination,
  },
  name: "Profile",
  data() {
    return {
      loader: true,
      role: this.$store.getters.getUser?.role,
      type: "Brokerage_List",
      list: [],
      fromDate: "",
      activeName: "second",
      order_type: "Default",
      toDate: "",
    };
  },
  mounted() {
    this.getList(0);
  },
  methods: {
    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(1);
    },
    getList(page) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};
      if (this.id) {
        searchObj.id = this.id;
      }
      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      this.$api
        .getAPI({
          _action: "/brokerage-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res;
          res.list = this.list;
          if (page == 1) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Data available");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    reset() {
      this.fromDate = "";
      this.toDate = "";
      this.type = "";
    },
    getDate(date) {
      return date ? moment(date).format("DD/MM/YYYY hh:mm A") : "-";
    },
    getSrNo(index) {
      return this.$refs.listPagination
        ? (this.$refs.listPagination.currentPage - 1) * 10 + (index + 1)
        : index + 1;
    },
    showDeletePopup(data) {
      swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to Brokerage",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-blue btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .deleteAPI({
                _action: "/brokerage/" + data.id,
              })
              .then((res) => {
                if (res) {
                  this.$notify({
                    title: "Success",
                    type: "success",
                    text: res.message,
                  });
                  this.getList(0);
                }
              })
              .catch(() => { });
          } else {
            this.getList(0);
          }
        });
    },
  },
};
</script>
<style>
.demo-tabs>.el-tabs__content {
  padding: 15px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
  padding: 0 !important;
}
</style>
