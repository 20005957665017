<template>
  <div>
    <div class="container form-signin3">
      <el-tabs
        v-model="activeName"
        style="max-width: 400px"
        class="demo-tabs"
        :class="id ? 'col-4' : 'col-3'"
        @tab-change="handleClick">
        <!-- :class="details.role == 'USER' && id ? 'col-3' : 'col-2'" -->
        <el-tab-pane label="Basic Information" name="first" id="first-tab">
        </el-tab-pane>
        <!-- v-if="details.role == 'USER' && id" -->
        <el-tab-pane
          v-if="id && details?.role != 'MANAGER'"
          label="Brokerage"
          name="second"
          id="second-tab"></el-tab-pane>
        <el-tab-pane
          v-if="id && details?.is_script_wise && details?.role == 'MASTER'"
          label="Script Wise Margin"
          name="third"
          id="third-tab"></el-tab-pane>
      </el-tabs>
      <div class="card-bg col-md-12 dealer-form form-signin100 mt-0">
        <div class="container">
          <div>
            <div>
              <div class="">
                <Form ref="account" @submit="save()">
                  <div class="col-lg-12 col-xs-12 form row mb-2 border-page">
                    <div class="col-lg-4 col-xs-12" style="text-align: left">
                      <label class="col-form-label" style="display: flex">
                        Account Type
                        <span class="text-danger">*</span>
                      </label>
                      <Field
                        name="role"
                        class="register-select"
                        v-model="details.role"
                        as="select"
                        :disabled="id"
                        rules="selectRequired:role"
                        :validateOnInput="true">
                        <option value="" disabled>Account Type</option>
                        <option
                          v-for="(data, index) in list"
                          :key="index"
                          :value="data.id">
                          {{ data.type }}
                        </option>
                      </Field>
                      <ErrorMessage name="role" class="text-danger" />
                    </div>
                    <div
                      class="col-lg-4 col-xs-12"
                      style="text-align: left"
                      v-if="role == 'SUPER_MASTER' && details.role == 'MASTER'">
                      <label class="col-form-label" style="display: flex">
                        Manager
                        <span class="text-danger">*</span>
                      </label>
                      <Field
                        name="manager_id"
                        class="register-select"
                        v-model="details.manager_id"
                        as="select">
                        <option value="">Select Manager</option>
                        <option
                          v-for="(data, index) in managerList"
                          :key="index"
                          :value="data.id">
                          {{ data.name }} ({{ data.code }})
                        </option>
                      </Field>
                    </div>
                    <div
                    v-if="
                        (role == 'SUPER_MASTER' ||  role == 'MANAGER') &&
                        (details.role == 'USER' || details.role == 'BROKER')
                      "
                      class="col-lg-4 col-xs-12"
                      style="text-align: left"
                     >
                      <label class="col-form-label" style="display: flex">
                        Master
                        <span class="text-danger">*</span>
                      </label>
                      <Field
                        name="master_id"
                        class="register-select"
                        v-model="details.master_id"
                        rules="selectRequired:master"
                        :validateOnInput="true"
                        v-on:Change="updatePermissions(details.master_id)"
                        as="select">
                        <option value="">Select Master</option>
                        <option
                          v-for="(data, index) in masterList"
                          :key="index"
                          :value="data.id">
                          {{ data.name }} ({{ data.code }})
                        </option>
                      </Field>
                      <ErrorMessage name="master_id" class="text-danger" />
                    </div>
                    <div
                      class="col-lg-4 col-xs-12"
                      style="text-align: left"
                      v-if="details.role == 'BROKER'">
                      <label class="col-form-label" style="display: flex">
                        Broker Type
                        <span class="text-danger">*</span>
                      </label>
                      <Field
                        name="broker_type"
                        class="register-select"
                        v-model="details.broker_type"
                        as="select"
                        rules="selectRequired: broker type"
                        :validateOnInput="true">
                        <option value="" disabled>Broker Type</option>
                        <option value="B1">BR1</option>
                        <option value="B2">BR2</option>
                        <option value="B3">BR3</option>
                      </Field>
                      <ErrorMessage name="broker_type" class="text-danger" />
                    </div>
                   <div
                      class="col-lg-4 col-xs-12"
                      style="text-align: left"
                      v-if="details.role == 'SUPER_MASTER'">
                      <label class="col-form-label" style="display: flex">
                        Company Name
                        <span class="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        class="form-control"
                        name="company_name"
                        placeholder="Company Name"
                        v-model="details.company_name"
                        rules="required:company name"
                        :validateOnInput="true" />
                      <ErrorMessage name="company_name" class="text-danger" />
                    </div>

                    <div
                      class="col-lg-4 col-xs-12"
                      style="text-align: left"
                      v-if="details.role == 'SUPER_MASTER'"></div>
                    <div class="col-lg-4 col-xs-12" style="text-align: left">
                      <label class="col-form-label" style="display: flex">
                        Account Name
                        <span class="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        class="form-control"
                        name="account_Name"
                        placeholder="Account Name"
                        v-model="details.name"
                        rules="required:account name"
                        :validateOnInput="true" />
                      <ErrorMessage name="account_Name" class="text-danger" />
                    </div>
                    <!-- <div class="col-4" v-if="id"></div> -->
                    <div class="col-lg-4 col-xs-12" style="text-align: left">
                      <label class="col-form-label" style="display: flex">
                        Account Code
                        <span class="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        class="form-control"
                        name="code"
                        placeholder="Code"
                        :disabled="id"
                        v-model="details.code"
                        rules="required:code"
                        :validateOnInput="true" />
                      <ErrorMessage name="code" class="text-danger" />
                    </div>
                    <div
                      class="col-lg-4 col-xs-12 form-group align-items-center mb-3"
                      v-if="!id"
                      style="text-align: left">
                      <label class="col-form-label" style="display: flex">
                        Password
                        <span class="text-danger">*</span>
                      </label>
                      <div class="input-group mb-3">
                        <Field
                          :type="newPasswordFieldType"
                          id="password"
                          name="password"
                          class="form-control password"
                          placeholder="Password"
                          v-model="details.password"
                          v-on:keydown.space="$event.preventDefault()"
                          rules="required"
                          :validateOnInput="true" />
                        <span
                          v-on:click="switchVisibilityNew"
                          class="input-group-text"
                          id="basic-addon2"
                          style="
                            border-radius: 0px 8px 8px 0px;
                            height: 30px;
                            margin-left: -3px;
                            cursor: pointer;
                          "
                          ><a class="psw-icon" style="margin-top: -6px"
                            ><img :src="imageNew" width="20" /></a
                        ></span>
                        <div style="width: 100%">
                          <ErrorMessage name="password" class="text-danger" />
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-2 col-xs-12" style="text-align: left">
                      <label class="col-form-label" style="display: flex">
                        Join Date <span class="text-danger">*</span></label
                      >

                      <Field
                        type="text"
                        name="join_date"
                        placeholder="Join Date"
                        rules="required: join date"
                        v-model="details.join_date"
                        :validateOnInput="true"
                        v-slot="{ field }"
                        v-bind="field">
                        <datepicker
                          v-model="details.join_date"
                          :typeable="true"
                          style="color: black"
                          input-format="dd-MM-yyyy"
                          class="form-control"
                          placeholder="Join Date" />
                        <ErrorMessage name="join_date" class="text-danger" />
                      </Field>
                    </div>
                    <div class="col-lg-2 col-xs-12" style="text-align: left">
                      <label class="col-form-label" style="display: flex">
                        End Date <span class="text-danger">*</span></label
                      >
                      <Field
                        type="text"
                        name="expire_date"
                        placeholder="Executive Name"
                        rules="required: expire date"
                        v-model="details.expire_date"
                        :validateOnInput="true"
                        v-slot="{ field }"
                        v-bind="field">
                        <datepicker
                          v-model="details.expire_date"
                          style="color: black"
                          input-format="dd-MM-yyyy"
                          class="form-control"
                          typeable="true"
                          placeholder="Expire Date" />
                        <ErrorMessage name="expire_date" class="text-danger" />
                      </Field>
                    </div>
                    <!-- <div class="col-4" v-if="details.role != 'SUPER_MASTER'">
                      <label class="col-form-label" style="display: flex">
                        Opening Balance
                      </label>
                      <Field
                        type="text"
                        class="form-control"
                        name="opening_balance"
                        placeholder="Opening Balance"
                        v-model="details.opening_balance"
                        v-on:keypress="$helperService.allowDecimalValue($event)"
                      />
                    </div> -->
                    <!-- <div
                      class="col-lg-4 col-xs-12"
                      v-if="details.role == 'BROKER'"
                    >
                      <label class="col-form-label" style="display: flex">
                        Sharing
                      </label>
                      <Field
                        type="text"
                        class="form-control"
                        name="sharing"
                        placeholder="Sharing"
                        v-model="details.sharing"
                        v-on:keypress="$helperService.allowDecimalValue($event)"
                      />
                    </div> -->
                    <div
                      class="col-1"
                      v-if="
                        details.role == 'SUPER_MASTER' ||
                        details.role == 'MASTER'
                      ">
                      <label
                        class="col-form-label"
                        style="display: flex; font-size: 13px !important">
                        Net
                      </label>
                      <input
                        id="is_sharing_gross"
                        name="is_sharing_gross"
                        type="checkbox"
                        v-model="details.is_sharing_gross"
                        class=""
                        :true-value="1"
                        :false-value="0" />
                    </div>
                    <div
                      class="col-3"
                      v-if="
                        details.role == 'SUPER_MASTER' ||
                        details.role == 'MASTER'
                      ">
                      <label class="col-form-label" style="display: flex">
                        {{
                          details.is_sharing_gross == 1
                            ? "Net Sharing %"
                            : "Gross Sharing %"
                        }}
                      </label>
                      <Field
                        type="text"
                        class="form-control"
                        name="Sharing"
                        placeholder="Sharing"
                        v-model="details.sharing"
                        v-on:keypress="$helperService.checkMinAndMax($event)" />
                      <span
                        v-if="details.sharing"
                        style="
                          text-align: left;
                          color: black;
                          font-size: 13px;
                          display: flex;
                        ">
                        {{ $helperService.convert(details.sharing) }}</span
                      >
                    </div>
                    <div
                      class="col-lg-4 col-xs-12"
                      v-if="
                        details.role == 'MANAGER' || details.role == 'MASTER'
                      ">
                      <label class="col-form-label" style="display: flex">
                        NSE MARGIN LIMIT
                      </label>
                      <Field
                        type="text"
                        class="form-control"
                        name="nse_master_limit"
                        placeholder="NSE MARGIN LIMIT"
                        :disabled="details.is_script_wise"
                        v-model="details.nse_master_limit"
                        v-on:keypress="
                          $helperService.allowDecimalValue($event)
                        " />
                      <span
                        v-if="details.nse_master_limit"
                        style="
                          text-align: left;
                          color: black;
                          font-size: 13px;
                          display: flex;
                        ">
                        {{
                          $helperService.convert(details.nse_master_limit)
                        }}</span
                      >
                    </div>
                    <div
                      class="col-lg-4 col-xs-12"
                      v-if="
                        details.role == 'MANAGER' || details.role == 'MASTER'
                      ">
                      <label class="col-form-label" style="display: flex">
                        MCX MARGIN LIMIT
                      </label>
                      <Field
                        type="text"
                        class="form-control"
                        name="mcx_master_limit"
                        placeholder="MCX MARGIN LIMIT"
                        :disabled="details.is_script_wise"
                        v-model="details.mcx_master_limit"
                        v-on:keypress="
                          $helperService.allowDecimalValue($event)
                        " />
                      <span
                        v-if="details.mcx_master_limit"
                        style="
                          text-align: left;
                          color: black;
                          font-size: 13px;
                          display: flex;
                        ">
                        {{
                          $helperService.convert(details.mcx_master_limit)
                        }}</span
                      >
                    </div>
                     <div
                      class="col-lg-4 col-xs-12"
                      v-if="
                        details.role == 'MANAGER' || details.role == 'MASTER'
                      ">
                      <label class="col-form-label" style="display: flex">
                        COMEX MARGIN LIMIT
                      </label>
                      <Field
                        type="text"
                        class="form-control"
                        name="comex_master_limit"
                        placeholder="COMEX MARGIN LIMIT"
                        :disabled="details.is_script_wise"
                        v-model="details.comex_master_limit"
                        v-on:keypress="
                          $helperService.allowDecimalValue($event)
                        " />
                      <span
                        v-if="details.comex_master_limit"
                        style="
                          text-align: left;
                          color: black;
                          font-size: 13px;
                          display: flex;
                        ">
                        {{
                          $helperService.convert(details.comex_master_limit)
                        }}</span
                      >
                    </div>
                    <div
                      class="col-lg-4 col-xs-12"
                      style="text-align: left"
                      v-if="details.role == 'MASTER'">
                      <label class="col-form-label" style="display: flex">
                        Ledger Balance Limit
                      </label>
                      <Field
                        type="text"
                        class="form-control"
                        name="ledger_balance_new"
                        rules="required:Ledger Balance Limit"
                        :validateOnInput="true"
                        placeholder="Ledger Balance Limit"
                        v-model="details.ledger_balance"
                        v-on:keypress="
                          $helperService.allowDecimalValue($event)
                        " />
                      <span
                        v-if="details.ledger_balance"
                        style="
                          text-align: left;
                          color: black;
                          font-size: 13px;
                          display: flex;
                        ">
                        {{
                          $helperService.convert(details.ledger_balance)
                        }}</span
                      >
                      <ErrorMessage
                        name="ledger_balance_new"
                        class="text-danger" />
                    </div>
                    <div
                      style="text-align: left"
                      class="col-lg-4 col-xs-12"
                      v-if="details.role == 'MASTER'">
                      <label class="col-form-label" style="display: flex">
                        Number Of Client Limit
                      </label>
                      <Field
                        type="text"
                        class="form-control"
                        name="no_of_clients"
                        placeholder="No Of Client Limit"
                        rules="required:No Of Client Limit"
                        :validateOnInput="true"
                        v-model="details.no_of_clients"
                        v-on:keypress="
                          $helperService.allowDecimalValue($event)
                        " />
                      <span
                        v-if="details.no_of_clients"
                        style="
                          text-align: left;
                          color: black;
                          font-size: 13px;
                          display: flex;
                        ">
                        {{
                          $helperService.convert(details.no_of_clients)
                        }}</span
                      >
                      <ErrorMessage name="no_of_clients" class="text-danger" />
                    </div>
                    <div
                      class="col-lg-1 col-xs-12"
                      v-if="details.role == 'MASTER'">
                      <label class="col-form-label" style="display: flex">
                        Fresh Stoploss
                      </label>
                      <div class="col-1 col-xs-12" style="display: grid">
                        <input
                          name="orderBetweenHighLow"
                          type="checkbox"
                          v-model="details.is_fresh_order"
                          class="ml-1"
                          :true-value="1"
                          :false-value="0" />
                      </div>
                    </div>
                    <div
                      class="col-lg-1 col-xs-12"
                      v-if="details.role == 'MASTER'">
                      <label class="col-form-label" style="display: flex">
                        Auto Square Off
                      </label>
                      <div class="col-1" style="display: grid">
                        <input
                          name="AutoSquareOff"
                          type="checkbox"
                          v-model="details.is_auto_square_off"
                          class="ml-1"
                          :true-value="1"
                          :false-value="0" />
                      </div>
                    </div>
                    <div
                      class="col-lg-2 col-xs-12"
                      v-if="details.role == 'MASTER'">
                      <label class="col-form-label" style="display: flex">
                        SCRIPT WISE MARGIN
                      </label>
                      <div class="col-1 col-xs-12" style="display: grid">
                        <input
                          name="is_script_wise"
                          type="checkbox"
                          v-model="details.is_script_wise"
                          class="ml-1"
                          :true-value="1"
                          :false-value="0" />
                      </div>
                    </div>
                    <div
                      class="col-lg-2 col-xs-12"
                      v-if="details.role == 'SUPER_MASTER'">
                      <label class="col-form-label" style="display: flex">
                        NO. OF Master
                      </label>
                      <Field
                        type="text"
                        class="form-control"
                        name="no_of_master"
                        placeholder="NO. OF Master"
                        v-model="details.no_of_master"
                        v-on:keypress="
                          $helperService.allowDecimalValue($event)
                        " />
                    </div>
                    <div
                      class="col-lg-2 col-xs-12"
                      v-if="details.role == 'SUPER_MASTER'">
                      <label class="col-form-label" style="display: flex">
                        NO. OF Broker
                      </label>
                      <Field
                        type="text"
                        class="form-control"
                        name="no_of_broker"
                        placeholder="NO. OF Broker"
                        v-model="details.no_of_broker"
                        v-on:keypress="
                          $helperService.allowDecimalValue($event)
                        " />
                    </div>
                    <div
                      class="col-lg-2 col-xs-12"
                      v-if="details.role == 'MASTER'">
                      <label class="col-form-label" style="display: flex">
                        Jobbing Trade Delay
                      </label>
                      <Field
                        type="text"
                        class="form-control"
                        name="minute"
                        placeholder="Minutes"
                        v-model="details.minute"
                        v-on:keypress="
                          $helperService.allowWholeNumber($event)
                        " />
                    </div>
                    <div
                      class="col-lg-2 col-xs-12"
                      v-if="details.role == 'MASTER'">
                      <label class="col-form-label" style="display: flex">
                        Trade Delayed (milliseconds)
                      </label>
                      <Field
                        type="number"
                        class="form-control"
                        name="delay_time"
                        placeholder="Trade Delayed (milliseconds)"
                        v-model="details.delay_time"
                        :validateOnInput="true"
                        rules="maxValue:10000"
                        v-on:keypress="
                          $helperService.allowWholeNumber($event)
                        " />
                      <ErrorMessage name="delay_time" class="text-danger" />
                    </div>
                    <div
                      class="col-lg-2 col-xs-12"
                      v-if="details.role == 'SUPER_MASTER'">
                      <label class="col-form-label" style="display: flex">
                        NO. OF User
                      </label>
                      <Field
                        type="text"
                        class="form-control"
                        name="no_of_user"
                        placeholder="NO. OF User"
                        v-model="details.no_of_user"
                        v-on:keypress="
                          $helperService.allowDecimalValue($event)
                        " />
                    </div>
                    <div
                      class="col-lg-1 col-xs-12"
                      style="margin-top: 3px"
                      v-if="details.role == 'SUPER_MASTER'">
                      <label class="col-form-label" style="display: flex">
                        Allow NSE MARKET
                      </label>
                      <input
                        type="checkbox"
                        v-model="details.is_allow_nse"
                        class="ml-1"
                        true-value="1"
                        false-value="0" />
                    </div>
                    <div
                      class="col-lg-1 col-xs-12"
                      style="margin-top: 3px"
                      v-if="details.role == 'SUPER_MASTER'">
                      <label class="col-form-label" style="display: flex">
                        M2M Settlement
                      </label>
                      <input
                        type="checkbox"
                        v-model="details.is_settlement"
                        class="ml-1"
                        :disabled="id"
                        true-value="1"
                        false-value="0" />
                    </div>
                    <div
                      class="col-lg-1 col-xs-12"
                      style="margin-top: 3px"
                      v-if="details.role == 'SUPER_MASTER'">
                      <label class="col-form-label" style="display: flex">
                        Allow MCX MARKET
                      </label>
                      <input
                        type="checkbox"
                        v-model="details.is_allow_mcx"
                        class="ml-1"
                        true-value="1"
                        false-value="0" />
                    </div>
                    <div
                      class="col-lg-2 col-xs-12"
                      style="margin-top: 3px"
                      v-if="details.role == 'SUPER_MASTER'">
                      <label class="col-form-label" style="display: flex">
                        Add Trade Permission
                      </label>
                      <input
                        type="checkbox"
                        v-model="details.is_add_trade_permission"
                        class="ml-1"
                        true-value="1"
                        false-value="0" />
                    </div>
                    <div
                      class="col-lg-12 col-xs-12"
                      v-if="details.role == 'SUPER_MASTER'">
                      <label class="col-form-label" style="display: flex">
                        Note
                      </label>
                      <textarea
                        style="height: auto !important"
                        type="text"
                        class="form-control"
                        name="note"
                        placeholder="Note"
                        v-model="details.note" />
                    </div>
                  </div>

                  <div
                    class="col-lg-12 col-xs-12 row"
                    v-if="details.role == 'USER'">
                    <div
                      class="col-lg-4 col-xs-12 form row mb-2 border-page mr-1">
                      <div
                        class="col-lg-12 col-xs-12 bg-transparent card-header text-left"
                        style="max-height: 52px !important">
                        <h5
                          style="
                            color: black !important;
                            margin: 0;
                            padding: 0;
                          ">
                          Broker
                        </h5>
                      </div>
                      <div
                        class="col-12 row"
                        v-if="role != 'BROKER' || details.broker_id == userid">
                        <div class="col-5 mt-4">
                          <label class="col-form-label" style="display: flex">
                            Broker First
                          </label>

                          <Field
                            name="broker_id"
                            class="register-select"
                            v-model="details.broker_id"
                            as="select">
                            <option value="">Broker First</option>
                            <option
                              v-for="(data, index) in brokerList1"
                              :key="index"
                              :value="data.id">
                              {{ data.name }}
                            </option>
                          </Field>
                        </div>
                        <div class="col-3 mt-4">
                          <label
                            class="col-form-label"
                            style="display: flex; font-size: 13px !important">
                            Net
                          </label>
                          <input
                            id="is_broker_gross"
                            name="is_broker_gross"
                            type="checkbox"
                            v-model="details.is_broker_gross"
                            class="ml-1"
                            :true-value="1"
                            :false-value="0" />
                        </div>
                        <div class="col-4 mt-4">
                          <label class="col-form-label" style="display: flex">
                            {{
                              details.is_broker_gross == 1
                                ? "Net Sharing %"
                                : "Gross Sharing %"
                            }}
                          </label>
                          <Field
                            type="text"
                            class="form-control"
                            name="is_broker_gross_per"
                            placeholder="Percentage"
                            v-model="details.is_broker_gross_per"
                            v-on:keypress="
                              $helperService.checkMinAndMax($event)
                            " />
                          <span
                            v-if="details.is_broker_gross_per"
                            style="
                              text-align: left;
                              color: black;
                              font-size: 13px;
                              display: flex;
                            ">
                            {{
                              $helperService.convert(
                                details.is_broker_gross_per
                              )
                            }}</span
                          >
                        </div>
                      </div>
                      <div
                        v-if="role != 'BROKER' || details.broker_id_2 == userid"
                        class="col-lg-12 row">
                        <div class="col-5 mt-4">
                          <label class="col-form-label" style="display: flex">
                            Broker Second
                          </label>

                          <Field
                            name="broker_id_2"
                            class="register-select"
                            v-model="details.broker_id_2"
                            as="select">
                            <option value="">Broker Second</option>
                            <option
                              v-for="(data, index) in brokerList2"
                              :key="index"
                              :value="data.id">
                              {{ data.name }}
                            </option>
                          </Field>
                        </div>
                        <div class="col-3 mt-4">
                          <label
                            class="col-form-label"
                            style="display: flex; font-size: 13px !important">
                            Net
                          </label>
                          <input
                            id="is_broker_2_gross"
                            name="is_broker_2_gross"
                            type="checkbox"
                            v-model="details.is_broker_2_gross"
                            class="ml-1"
                            :true-value="1"
                            :false-value="0" />
                        </div>
                        <div class="col-4 mt-4">
                          <label class="col-form-label" style="display: flex">
                            {{
                              details.is_broker_2_gross == 1
                                ? "Net Sharing %"
                                : "Gross Sharing %"
                            }}
                          </label>
                          <Field
                            type="text"
                            class="form-control"
                            name="is_broker_2_gross_per"
                            placeholder="Percentage"
                            v-model="details.is_broker_2_gross_per"
                            v-on:keypress="
                              $helperService.checkMinAndMax($event)
                            " />
                          <span
                            v-if="details.is_broker_2_gross_per"
                            style="
                              text-align: left;
                              color: black;
                              font-size: 13px;
                              display: flex;
                            ">
                            {{
                              $helperService.convert(
                                details.is_broker_2_gross_per
                              )
                            }}</span
                          >
                        </div>
                      </div>
                      <div
                        class="col-lg-12 col-xs-12 row"
                        v-if="
                          role != 'BROKER' || details.broker_id_3 == userid
                        ">
                        <div class="col-5 col-xs-12 mt-4">
                          <label class="col-form-label" style="display: flex">
                            Broker Third
                          </label>

                          <Field
                            name="broker_id_3"
                            class="register-select"
                            v-model="details.broker_id_3"
                            as="select">
                            <option value="">Broker Third</option>
                            <option
                              v-for="(data, index) in brokerList3"
                              :key="index"
                              :value="data.id">
                              {{ data.name }}
                            </option>
                          </Field>
                        </div>
                        <div class="col-3 col-xs-12 mt-4">
                          <label
                            class="col-form-label"
                            style="display: flex; font-size: 13px !important">
                            Net
                          </label>
                          <input
                            id="is_broker_3_gross"
                            name="is_broker_3_gross"
                            type="checkbox"
                            v-model="details.is_broker_3_gross"
                            class="ml-1"
                            :true-value="1"
                            :false-value="0" />
                        </div>
                        <div class="col-4 col-xs-12 mt-4">
                          <label class="col-form-label" style="display: flex">
                            {{
                              details.is_broker_3_gross == 1
                                ? "Net Sharing %"
                                : "Gross Sharing %"
                            }}
                          </label>
                          <Field
                            type="text"
                            class="form-control"
                            name="is_broker_3_gross_per"
                            placeholder="Percentage"
                            v-model="details.is_broker_3_gross_per"
                            v-on:keypress="
                              $helperService.checkMinAndMax($event)
                            " />
                          <span
                            v-if="details.is_broker_3_gross_per"
                            style="
                              text-align: left;
                              color: black;
                              font-size: 13px;
                              display: flex;
                            ">
                            {{
                              $helperService.convert(
                                details.is_broker_3_gross_per
                              )
                            }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-lg-4 col-xs-12 form row mb-2 border-page mr-2 ml-2">
                      <div
                        class="col-12 bg-transparent card-header text-left"
                        style="height: 50px !important">
                        <h5
                          style="
                            color: black !important;
                            margin: 0;
                            padding: 0;
                          ">
                          Segment
                          <span
                            class="text-danger"
                            style="font-size: 20px !important"
                            >*</span
                          >
                        </h5>
                      </div>
                      <div class="col-lg-12 col-xs-12 row">
                        <div class="col-lg-4 col-xs-12 row mt-1">
                          <div class="col-2 col-xs-12 mt-2">
                            <label
                              class="col-form-label"
                              style="display: flex; font-size: 13px !important">
                              NSE
                            </label>
                          </div>
                          <div class="col-2 col-xs-12 mt-3">
                            <input
                              id="is_nse"
                              name="is_nse"
                              type="checkbox"
                              v-on:change="check()"
                              v-model="details.is_allow_nse"
                              class="ml-1"
                              :true-value="1"
                              :false-value="0" />
                          </div>
                        </div>
                        <div class="col-lg-8 col-xs-12 row mt-3">
                          <Field
                            type="text"
                            class="form-control"
                            name="nse_margin"
                            placeholder="NSE Margin"
                            v-model="details.nse_margin"
                            :disabled="is_script_wise"
                            v-on:keypress="
                              $helperService.allowDecimalValue($event)
                            " />
                          <span
                            v-if="details.nse_margin"
                            style="
                              text-align: left;
                              color: black;
                              font-size: 13px;
                              display: flex;
                            ">
                            {{
                              $helperService.convert(details.nse_margin)
                            }}</span
                          >
                        </div>
                      </div>
                      <div class="col-lg-12 col-xs-12 row">
                        <div class="col-lg-4 col-xs-12 row mt-1">
                          <div class="col-2 mt-2">
                            <label
                              class="col-form-label"
                              style="display: flex; font-size: 13px !important">
                              MCX
                            </label>
                          </div>
                          <div class="col-2 mt-3">
                            <input
                              id="is_mcx"
                              name="is_mcx"
                              type="checkbox"
                              v-model="details.is_allow_mcx"
                              class="ml-1"
                              true-value="1"
                              false-value="0" />
                          </div>
                        </div>
                        <div class="col-lg-8 col-xs-3 row mt-3">
                          <Field
                            type="text"
                            class="form-control"
                            name="mcx_margin"
                            :disabled="is_script_wise"
                            placeholder="MCX Margin"
                            v-model="details.mcx_margin"
                            v-on:keypress="
                              $helperService.allowDecimalValue($event)
                            " />
                          <span
                            v-if="details.mcx_margin"
                            style="
                              text-align: left;
                              color: black;
                              font-size: 13px;
                              display: flex;
                            ">
                            {{
                              $helperService.convert(details.mcx_margin)
                            }}</span
                          >
                        </div>
                      </div>
                      <div class="col-lg-12 col-xs-12 row">
                        <div class="col-lg-4 col-xs-12 row mt-1">
                          <div class="col-2 col-xs-12 mt-2">
                            <label
                              class="col-form-label"
                              style="display: flex; font-size: 11px !important">
                              COMEX
                            </label>
                          </div>
                          <div class="col-2 col-xs-12 mt-3 ml-3">
                            <input
                              id="is_nse"
                              name="is_nse"
                              type="checkbox"
                              v-on:change="check()"
                              v-model="details.is_allow_comex"
                              class="ml-1"
                              :true-value="1"
                              :false-value="0" />
                          </div>
                        </div>
                        <div class="col-lg-8 col-xs-12 row mt-3">
                          <Field
                            type="text"
                            class="form-control"
                            name="comex_margin"
                            placeholder="COMEX Margin"
                            v-model="details.comex_margin"
                            :disabled="is_script_wise"
                            v-on:keypress="
                              $helperService.allowDecimalValue($event)
                            " />
                          <span
                            v-if="details.comex_margin"
                            style="
                              text-align: left;
                              color: black;
                              font-size: 13px;
                              display: flex;
                            ">
                            {{
                              $helperService.convert(details.comex_margin)
                            }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-lg-4 col-xs-12 form row mb-2 border-page ml-2">
                      <div class="col-12 bg-transparent card-header text-left">
                        <h5
                          style="
                            color: black !important;
                            margin: 0;
                            padding: 0;
                          ">
                          Setting
                        </h5>
                      </div>
                      <div
                        class="col-lg-12 col-xs-12"
                        v-if="details.role == 'USER'">
                        <label class="col-form-label" style="display: flex">
                          Ledger Balance
                        </label>
                        <Field
                          type="text"
                          class="form-control"
                          :disabled="
                            this.$store.getters.getAccess?.is_ledgerEdit == 0
                          "
                          name="ledger_balance"
                          placeholder="Ledger Balance"
                          v-model="details.ledger_balance"
                          v-on:keypress="
                            $helperService.allowDecimalValue($event)
                          " />
                        <span
                          v-if="details.ledger_balance"
                          style="
                            text-align: left;
                            color: black;
                            font-size: 13px;
                            display: flex;
                          ">
                          {{
                            $helperService.convert(details.ledger_balance)
                          }}</span
                        >
                      </div>
                      <div
                        class="col-lg-6 col-xs-12"
                        v-if="details.role == 'USER'">
                        <label class="col-form-label" style="display: flex">
                          MTM Loss %
                        </label>
                        <Field
                          type="text"
                          class="form-control"
                          name="mtm_loss_per"
                          placeholder="MTM percentage"
                          v-model="details.mtm_loss_per"
                          v-on:keypress="
                            $helperService.checkMinAndMax($event)
                          " />
                        <span
                          v-if="details.mtm_loss_per"
                          style="
                            text-align: left;
                            color: black;
                            font-size: 13px;
                            display: flex;
                          ">
                          {{
                            $helperService.convert(details.mtm_loss_per)
                          }}</span
                        >
                      </div>
                      <div
                        class="col-lg-6 col-xs-12"
                        v-if="details.role == 'USER'">
                        <label class="col-form-label" style="display: flex">
                          MTM Profit %
                        </label>
                        <Field
                          type="text"
                          class="form-control"
                          name="mtm_percentage"
                          placeholder="MTM percentage"
                          v-model="details.mtm_percentage"
                          v-on:keypress="
                            $helperService.checkMinAndMax($event)
                          " />
                        <span
                          v-if="details.mtm_percentage"
                          style="
                            text-align: left;
                            color: black;
                            font-size: 13px;
                            display: flex;
                          ">
                          {{
                            $helperService.convert(details.mtm_percentage)
                          }}</span
                        >
                      </div>

                      <div
                        class="col-lg-12 col-xs-12"
                        v-if="details.role == 'USER'">
                        <label class="col-form-label" style="display: flex">
                          Close %</label
                        >
                        <Field
                          type="text"
                          class="form-control"
                          name="close_percentage"
                          placeholder="Close Percentage"
                          v-model="details.close_percentage"
                          v-on:keypress="
                            $helperService.checkMinAndMax($event)
                          " />
                        <span
                          v-if="details.close_percentage"
                          style="
                            text-align: left;
                            color: black;
                            font-size: 13px;
                            display: flex;
                          ">
                          {{
                            $helperService.convert(details.close_percentage)
                          }}</span
                        >
                      </div>
                      <div class="row col-lg-12 col-xs-12 mt-2">
                        <div class="col-lg-5 col-xs-6">
                          <label class="col-form-label" style="display: flex">
                            Intrday Auto Square Off
                          </label>
                        </div>
                        <div class="col-1 col-xs-6" style="display: grid">
                          <input
                            name="IntradyAutoSquareOff"
                            type="checkbox"
                            class="ml-1"
                            v-model="details.is_intraday_auto_square_off"
                            :true-value="1"
                            :false-value="0" />
                        </div>

                        <div class="col-lg-5 col-xs-6">
                          <label class="col-form-label" style="display: flex">
                            Auto Square Off
                          </label>
                        </div>
                        <div class="col-1" style="display: grid">
                          <input
                            name="AutoSquareOff"
                            type="checkbox"
                            :disabled="is_auto_square_off == 1"
                            v-model="details.is_auto_square_off"
                            class="ml-1"
                            :true-value="1"
                            :false-value="0" />
                        </div>
                        <div class="col-lg-5 col-xs-6">
                          <label class="col-form-label" style="display: flex">
                            Limit Order
                          </label>
                        </div>
                        <div class="col-1 col-xs-6" style="display: grid">
                          <input
                            name="LimitOrder"
                            type="checkbox"
                            v-model="details.is_limit_order"
                            class="ml-1"
                            :true-value="1"
                            :false-value="0" />
                        </div>

                        <div class="col-lg-5 col-xs-6">
                          <label class="col-form-label" style="display: flex">
                            Only Position Square Off
                          </label>
                        </div>
                        <div class="col-1 col-xs-6" style="display: grid">
                          <input
                            name="OnlyPositionSquareOff"
                            type="checkbox"
                            v-model="details.is_only_position_square_off"
                            class="ml-1"
                            :true-value="1"
                            :false-value="0" />
                        </div>

                        <div class="col-lg-5 col-xs-12">
                          <label class="col-form-label" style="display: flex">
                            Order Between Hign Low
                          </label>
                        </div>
                        <div class="col-1 col-xs-12" style="display: grid">
                          <input
                            name="orderBetweenHighLow"
                            type="checkbox"
                            v-model="details.is_order_between_high_low"
                            class="ml-1"
                            :true-value="1"
                            :false-value="0" />
                        </div>
                        <div class="col-lg-5 col-xs-12">
                          <label class="col-form-label" style="display: flex">
                            Is Fresh Stoploss Order
                          </label>
                        </div>
                        <div class="col-1 col-xs-12" style="display: grid">
                          <input
                            name="orderBetweenHighLow"
                            type="checkbox"
                            :disabled="!is_fresh_order"
                            v-model="details.is_fresh_order"
                            class="ml-1"
                            :true-value="1"
                            :false-value="0" />
                        </div>
                        <div class="col-lg-5 col-xs-12">
                          <label class="col-form-label" style="display: flex">
                            Positional Brokerage
                          </label>
                        </div>
                        <div class="col-1 col-xs-12" style="display: grid">
                          <input
                            name="orderBetweenHighLow"
                            type="checkbox"
                            :disabled="is_positional_brokerage == 1"
                            v-model="details.is_positional_brokerage"
                            class="ml-1"
                            :true-value="1"
                            :false-value="0" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-md-12 text-center"
                    v-if="role != 'USER' && role != 'BROKER'">
                    <button
                      v-if="details.is_edit_permission == 1 || !id"
                      class="save-btn px-4 ml-3"
                      type="submit"
                      id="save-button">
                      SAVE
                    </button>
                    <button
                      v-if="id"
                      v-on:click="showChangePasswordPopup()"
                      style="width: 15% !important; height: 47% !important"
                      class="save-btn ml-3"
                      type="button">
                      <i class="fa fa-key mr-2" aria-hidden="true"></i>

                      <span class="align-middle">Change Password</span>
                    </button>
                  </div>

                  <div class="col-md-12 text-right"></div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetails()" ref="errorComponent" />
    <TextModal ref="textModal">
      <div class="modal-header pt-0">
        <h5 class="modal-title">Change Password</h5>
        <button
          type="button"
          class="close"
          v-on:click="$refs.textModal.closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form ref="changePasswordForm" @submit="changePassword()">
        <!-- <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>Old Password</label>
            <span class="text-danger">*</span>
            <Field
              type="password"
              name="_password"
              class="form-control"
              placeholder="Old Password"
              v-model="old_password"
              rules="required:old password"
              :validateOnInput="true"
            />
            <ErrorMessage name="_password" class="text-danger" />
          </div>
        </div> -->
        <div
          class="col-lg-12 col-xs-12 form-group align-items-center mb-3"
          style="text-align: left">
          <label class="col-form-label" style="display: flex">
            New Password
            <span class="text-danger">*</span>
          </label>
          <div class="input-group mb-3">
            <Field
              :type="newPasswordFieldType"
              name="name_password"
              class="form-control form-group"
              placeholder="New Password"
              v-model="new_password"
              rules="required:new password|minLength:6"
              :validateOnInput="true" /><span
              v-on:click="switchVisibilityNew"
              class="input-group-text"
              id="basic-addon2"
              style="
                border-radius: 0px 8px 8px 0px;
                height: 30px;
                margin-left: -3px;
                cursor: pointer;
              "
              ><a class="psw-icon" style="margin-top: -6px"
                ><img :src="imageNew" width="20" /></a
            ></span>
          </div>
          <ErrorMessage name="name_password" class="text-danger" />
        </div>
        <div
          class="col-lg-12 col-xs-12 form-group align-items-center mb-3"
          style="text-align: left">
          <label class="col-form-label" style="display: flex">
            Confirm Password
            <span class="text-danger">*</span>
          </label>
          <div class="input-group mb-3">
            <Field
              :type="newPasswordFieldTypeV2"
              placeholder="Confirm Password"
              name="name_ccpassword"
              class="form-control"
              rules="required:confirm password|confirmed:@name_password"
              :validateOnInput="true" />
            <span
              v-on:click="switchVisibilityNewV2"
              class="input-group-text"
              id="basic-addon2"
              style="
                border-radius: 0px 8px 8px 0px;
                height: 30px;
                margin-left: -3px;
                cursor: pointer;
              "
              ><a class="psw-icon" style="margin-top: -6px"
                ><img :src="imageNewV2" width="20" /></a
            ></span>
          </div>
          <ErrorMessage name="name_ccpassword" class="text-danger" />
        </div>
        <div class="pb-0 text-center">
          <button class="save-btn" id="change-password-button">Save</button>
        </div>
      </Form>
    </TextModal>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import TextModal from "@/components/TextModal";
import Datepicker from "vue3-datepicker";
import moment from "moment";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    TextModal,
    ErrorComponent,
    Datepicker,
  },
  watch: {
    "$route.fullPath"() {
      this.$refs.account.resetForm();
      this.id = "";
    },
  },

  name: "Profile",
  data() {
    return {
      newPasswordFieldType: "password",
      newPasswordFieldTypeV2: "password",
      PasswordFieldType: "password",
      image: "/images/show-psw.svg",
      imageNewV2: "/images/show-psw.svg",
      imageNew: "/images/show-psw.svg",
      id: this.$route.params.id,
      role: this.$store.getters.getUser?.role,
      userid: localStorage.getItem("userid"),
      password: "",
      activeName: "first",
      confirm_password: "",
      is_script_wise: 0,
      is_fresh_order: 0,
      is_auto_square_off: 0,
      list: [],
      details: {
        id: 1,
        role: "",
        broker_type: "",
        name: "",
        code: "",
        join_date: new Date(),
        expire_date: "",
        logo: "",
        company_name: "",
        sharing: "",
        brokerage: "",
        no_of_master: "",
        no_of_broker: "",
        no_of_user: "",
        note: "",
        is_allow_nse: 0,
        broker_id: "",
        broker_id_2: "",
        broker_id_3: "",
        is_nse: 0,
        is_mcx: 0,
        is_allow_mcx: 0,
        is_allow_mobile_application: 0,
        is_order_between_high_low: 1,
        is_intraday_auto_square_off: 0,
        opening_balance: "",
        sub_broker: "",
        is_auto_square_off: 0,
        is_limit_order: 1,
        is_only_position_square_off: 0,
        is_fresh_order: 0,
        margin_amount: "",
        ledger_balance: "",
        mtm_percentage: "",
        close_percentage: "",
        mtm_profit: "",
        mtm_loss: "",
      },
      bank: {},
      new_details: {},
      brokerList: [],
      masterList: [],
      managerList: [],
    };
  },
  mounted() {
    this.checkAccess();
    this.getMasterList();
    this.getTypeList();
    this.getBrokerList();
    this.getManagerList();
    if (this.id) {
      this.getDetails();
    } else {
      if (this.role == "MASTER") {
        this.details.master_id = this.userid;
      }
    }
  },
  computed: {
    brokerList1() {
      return this.brokerList.filter((value) => {
        return (
          value.broker_type == "B1" && value.master_id == this.details.master_id
        );
      });
    },
    brokerList2() {
      return this.brokerList.filter((value) => {
        return value.master_id == this.details.master_id;
      });
    },
    brokerList3() {
      return this.brokerList.filter((value) => {
        return value.master_id == this.details.master_id;
      });
    },
  },
  methods: {
    checkAccess() {
      var access = this.$store.getters.getAccess;
      if (
        access.is_addAccount == 0 &&
        this.role != "ADMIN" &&
        this.role != "SUPER_ADMIN"
      ) {
        this.$router.push("/access-denied");
      }
    },
    handleClick() {
      if (this.activeName == "second") {
        this.$router.push("/brokerages/" + this.id);
      } else if (this.activeName == "third") {
        this.$router.push("/master-script-margin-limit/" + this.id);
      }
    },
    updatePermissions(master_id) {
      for (var i in this.masterList) {
        if (master_id == this.masterList[i].id) {
          this.details.is_auto_square_off = this.masterList[i]
            .is_auto_square_off
            ? this.masterList[i].is_auto_square_off
            : this.new_details.is_auto_square_off;

          // if (this.masterList[i].is_fresh_order) {
          //   this.details.is_fresh_order = this.masterList[i].is_fresh_order;
          // }

          this.is_script_wise = this.masterList[i].is_script_wise
            ? this.masterList[i].is_script_wise
            : 0;
          if (this.masterList[i].is_fresh_order) {
            this.is_fresh_order = 1;
          } else {
            this.is_fresh_order = 0;
          }
          if (this.masterList[i].is_auto_square_off) {
            this.is_auto_square_off = 1;
          } else {
            this.is_auto_square_off = 0;
          }
        }
      }
    },
    getTypeList() {
      if (this.role == "ADMIN" || this.role == "SUPER_ADMIN") {
        this.list = [
          {
            id: "SUPER_MASTER",
            type: "Super Master",
          },
        ];
      } else if (this.role == "SUPER_MASTER") {
        this.list = [
          {
            id: "MASTER",
            type: "Master",
          },
          {
            id: "MANAGER",
            type: "Manager",
          },
          {
            id: "BROKER",
            type: "Broker",
          },
          {
            id: "USER",
            type: "User",
          },
        ];
      } else if (this.role == "MANAGER") {
        this.list = [
          {
            id: "MASTER",
            type: "Master",
          },
          {
            id: "BROKER",
            type: "Broker",
          },
          {
            id: "USER",
            type: "User",
          },
        ];
      } else if (this.role == "MASTER") {
        this.list = [
          {
            id: "BROKER",
            type: "Broker",
          },
          {
            id: "USER",
            type: "User",
          },
        ];
      } else if (this.role == "BROKER") {
        this.list = [
          {
            id: "USER",
            type: "User",
          },
        ];
      }
    },
    getDetails() {
      if (this.id) {
        this.$refs.errorComponent.updateFormLoader(true);
        this.$api
          .getAPI({
            _action: "/account-detail/" + this.id,
          })
          .then((res) => {
            res.expire_date = moment(res.expire_date).toDate();
            res.join_date = moment(res.join_date).toDate();
            this.details = res;
            this.new_details = res;
            this.updatePermissions(this.details.master_id);
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(res, "");
              this.$refs.errorComponent.updateFormLoader(false);
            }
          })
          .catch(() => {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setData(0, undefined, "");
              this.$refs.errorComponent.updateFormLoader(false);
            }
          });
      }
    },
    resetForm() {
      this.$refs.account.resetForm();
    },
    save() {
      if (
        !this.details.is_allow_mcx &&
        !this.details.is_allow_nse &&
        this.details.role == "USER" &&
        !this.details.is_allow_comex
      ) {
        this.$notify({
          title: "Success",
          type: "error",
          text: "Please Select Any Segment.",
        });
        return;
      }
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/account",
            _body: this.details,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (res) {
              this.$notify({
                title: "Success",
                text: res.message,
                type: "success",
              });
              if (this.details.role == "USER") {
                this.$router.push("/users");
              } else if (this.details.role == "BROKER") {
                this.$router.push("/broker");
              } else if (this.details.role == "MASTER") {
                this.$router.push("/masters");
              } else if (this.details.role == "MANAGER") {
                this.$router.push("/manager");
              } else if (this.details.role == "SUPER_MASTER") {
                this.$router.push("/super-masters");
              }
            }
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/account",
            _body: this.details,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (res) {
              this.$notify({
                title: "Success",
                type: "success",
                text: res.message,
              });
              if (this.details.role == "USER") {
                this.$router.push("/users");
              } else if (this.details.role == "BROKER") {
                this.$router.push("/broker");
              } else if (this.details.role == "MASTER") {
                if (this.details.is_script_wise == 1) {
                  this.$router.push("/master-script-margin-limit/" + res.id);
                } else {
                  this.$router.push("/masters");
                }
              } else if (this.details.role == "MANAGER") {
                this.$router.push("/manager");
              } else if (this.details.role == "SUPER_MASTER") {
                this.$router.push("/super-masters");
              }
            }
          })
          .catch(() => {});
      }
    },
    showChangePasswordPopup() {
      this.$refs.textModal.showModal();
      this.$refs.changePasswordForm.resetForm();
    },
    getBrokerList() {
      this.$api
        .getAPI({
          _action: "/broker-list",
        })
        .then((res) => {
          this.brokerList = res;
        })
        .catch(() => {});
    },
    getMasterList() {
      this.$api
        .getAPI({
          _action: "/master-list",
        })
        .then((res) => {
          this.masterList = res;
          this.updatePermissions(this.details.master_id);
        })
        .catch(() => {});
    },
    getManagerList() {
      this.$api
        .getAPI({
          _action: "/manager-list",
        })
        .then((res) => {
          this.managerList = res;
        })
        .catch(() => {});
    },
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew =
        this.imageNew === "/images/show-psw.svg"
          ? "/images/hide-psw.svg"
          : "/images/show-psw.svg";
    },
    switchVisibilityNewV2() {
      this.newPasswordFieldTypeV2 =
        this.newPasswordFieldTypeV2 === "password" ? "text" : "password";
      this.imageNewV2 =
        this.imageNewV2 === "/images/show-psw.svg"
          ? "/images/hide-psw.svg"
          : "/images/show-psw.svg";
    },
    changePassword() {
      var obj = {};
      obj.old_password = this.old_password;
      obj.new_password = this.new_password;
      obj.id = this.id;
      this.$api
        .putAPI({
          _action: "/reset/password",
          _body: obj,
          _buttonId: "change-password-button",
        })
        .then((res) => {
          this.$refs.textModal.closeModal();
          this.$notify({
            title: "Success",
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style>
.demo-tabs > .el-tabs__content {
  padding: 15px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
  padding: 0 !important;
}
</style>
