<template>
  <div>
    <div class="container form-signin3">
      <div class="card-bg col-md-12 dealer-form form-signin3 mt-0">
        <div class="container">
          <div class="border-page">
            <Form ref="account" @submit="save()">
              <div class="col-12 row">
                <div class="col-4"></div>

                <div class="col-4 form mb-2">
                  <div class="col-12">
                    <label class="col-form-label" style="display: flex">
                      Transfer Setting
                      <span class="text-danger">*</span>
                    </label>
                    <Multiselect
                      mode="single"
                      trackBy="name"
                      label="name"
                      valueProp="value"
                      class="register-select"
                      placeholder="Setting"
                      v-model="detail.setting"
                      :options="settings"
                      searchable="true" />
                  </div>
                  <div class="col-12">
                    <label class="col-form-label" style="display: flex">
                      Segment
                      <span class="text-danger">*</span>
                    </label>
                    <Multiselect
                      mode="single"
                      trackBy="name"
                      label="name"
                      valueProp="value"
                      class="register-select"
                      placeholder="Segment"
                      v-model="detail.exchange"
                      :options="exchanges"
                      searchable="true" />
                  </div>
                  <div class="col-12">
                    <label class="col-form-label" style="display: flex">
                      Client
                      <span class="text-danger">*</span>
                    </label>
                    <Multiselect
                      mode="single"
                      trackBy="new_name"
                      label="new_name"
                      valueProp="id"
                      class="register-select"
                      placeholder="From Client"
                      v-model="detail.from_user_id"
                      :options="client_list"
                      @change="getToClientList()"
                      searchable="true" />
                  </div>
                  <div class="col-12" v-if="detail.from_user_id">
                    <label class="col-form-label" style="display: flex">
                      To Client
                      <span class="text-danger">*</span>
                    </label>
                    <Multiselect
                      mode="multiple"
                      trackBy="new_name"
                      label="new_name"
                      valueProp="id"
                      class="register-select"
                      placeholder="To Client"
                      v-model="detail.to_user_id"
                      :options="client_list_new"
                      @select="getALlSelected($event)"
                      searchable="true" />
                  </div>

                  <div
                    class="col-md-12"
                    style="display: flex; justify-content: center">
                    <button
                      class="save-btn px-4 ml-3"
                      type="submit"
                      id="save-button">
                      Transfer
                    </button>
                  </div>
                </div>
                <div class="col-md-12 text-right"></div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetails()" ref="errorComponent" />
  </div>
</template>
<script>
import { Form } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Multiselect from "@vueform/multiselect";
export default {
  components: {
    Form,
    ErrorComponent,
    Multiselect,
  },
  watch: {
    "$route.fullPath"() {
      this.$refs.account.resetForm();
      this.id = "";
    },
  },
  name: "Profile",
  data() {
    return {
      id: this.$route.params.id,
      role: this.$store.getters.getUser?.role,
      password: "",
      confirm_password: "",
      list: [],
      detail: {
        to_user_id: [],
      },
      client_list: [],
      client_list_new: [],
      exchanges: [
        {
          name: "NSE",
          value: "NFO",
        },
        {
          name: "MCX",
          value: "MCX",
        },{
          name: "COMEX",
          value: "COMEX",
        },
      ],
      settings: [
        {
          name: "Quantity Setting",
          value: "QUANTITY_SETTING",
        },
        {
          name: "Client Order Limit Setting",
          value: "CLIENT_ORDER_LIMIT_SETTINGS",
        },
        {
          name: "Client Segment  Setting",
          value: "CLIENT_SEGMENT_SETTINGS",
        },
        {
          name: "Brokerage Setting",
          value: "BROKERAGE_SETTINGS",
        },
        {
          name: "Block Script",
          value: "BLOCK_SCRIPT",
        },
        {
          name: "All",
          value: "ALL",
        },
      ],
      bank: {},
    };
  },

  mounted() {
    this.checkAccess();
    this.getClientList();
  },
  methods: {
    checkAccess() {
      var access = this.$store.getters.getAccess;
      if (access.is_transferSetting == 0) {
        this.$router.push("/access-denied");
      }
    },
    getALlSelected(e) {
      if (e == "ALL") {
        for (var i in this.client_list_new) {
          if (this.client_list_new[i].id != "ALL") {
            this.detail.to_user_id.push(this.client_list_new[i].id);
          }
        }
      }
      console.log(e);
    },
    getToClientList() {
      this.$api
        .getAPI({
          _action: "/client-list",
        })
        .then((res) => {
          this.client_list_new = res;
          var arr = [];

          for (var i in this.client_list_new) {
            if (this.detail.from_user_id == this.client_list_new[i].id) {
              // this.client_list_new.splice(i, 1);
            } else {
              this.client_list_new[i].new_name =
                this.client_list_new[i].name +
                " (" +
                this.client_list_new[i].code +
                ")";
              arr.push(this.client_list_new[i]);
            }
          }

          this.client_list_new = arr;
          this.client_list_new.splice(0, 0, { id: "ALL", new_name: "ALL" });
        })
        .catch(() => {});
    },

    getClientList() {
      this.$api
        .getAPI({
          _action: "/client-list",
        })
        .then((res) => {
          for (var i in res) {
            res[i].new_name = res[i].name + " (" + res[i].code + ")";
          }
          this.client_list = res;
        })
        .catch(() => {});
    },
    save() {
      this.$api
        .postAPI({
          _action: "/transfer-client-setting",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (res) {
            this.$notify({
              title: "Success",
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
