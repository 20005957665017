<template>
  <div>
    <div class="container form-signin3">
      <div class="row">
        <div class="col-md-12" style="padding-left: 50px; padding-right: 50px">
          <h6
            class="text-start"
            style="color: rgb(29, 68, 91); font-weight: 600">
            TRADE BOOK
          </h6>
          <form class="form-inline transparent-form p10 border-page m-t-2">
            <div
              class="col-lg-12 col-sm-12 row"
              style="padding-right: 0px !important">
              <!-- <div class="col-lg-10 row"> -->
              <div class="col-lg-2 col-sm-3 mt-1" v-if="role != 'USER'">
                <Multiselect
                  mode="single"
                  trackBy="new_name"
                  label="new_name"
                  valueProp="id"
                  class="register-select"
                  placeholder=" Client"
                  v-model="user_id"
                  :options="client_list"
                  searchable="true" />
              </div>
              <div class="col-lg-2 col-sm-3 mt-1">
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="value"
                  class="register-select"
                  placeholder=" Exchange"
                  v-model="exchange"
                  :options="exchanges"
                  @select="getScriptName(exchange)"
                  searchable="true" />
              </div>
              <div class="col-lg-2 col-sm-3 mt-1">
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="name"
                  class="register-select"
                  placeholder=" Script"
                  v-model="name"
                  :options="scriptList"
                  searchable="true" />
              </div>
              <div class="col-lg-2 col-sm-3 mt-1">
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="value"
                  class="register-select"
                  placeholder="Order Type"
                  v-model="trade_type"
                  :options="tradeType"
                  searchable="true" />
              </div>
              <!-- </div> -->
              <div class="col-lg-2 col-sm-3 mt-1" v-if="role == 'SUPER_MASTER'">
                <Multiselect
                  mode="single"
                  trackBy="new_name"
                  label="new_name"
                  valueProp="id"
                  class="register-select"
                  placeholder="Master"
                  v-model="master_id"
                  :options="master_list"
                  searchable="true" />
              </div>
              <div
                class="col-lg-2 col-sm-3 mt-1"
                v-if="role == 'SUPER_MASTER' || role == 'MASTER'">
                <Multiselect
                  mode="single"
                  trackBy="new_name"
                  label="new_name"
                  valueProp="id"
                  class="register-select"
                  placeholder=" SubBroker"
                  v-model="broker_id"
                  :options="broker_list"
                  searchable="true" />
              </div>
            </div>
            <div
              class="col-lg-12 col-sm-12 row"
              style="margin-top: 14px; padding-right: 0px !important">
              <div class="col-lg-7 col-sm-12 row mt-1">
                <div class="col-lg-3 col-sm-3 mt-1">
                  <datepicker
                    v-model="fromDate"
                    class="form-control"
                    typeable="true"
                    inputFormat="dd-MM-yyyy"
                    placeholder="From Date"
                    style="width: 100% !important" />
                </div>
                <div class="col-lg-3 col-sm-3 mt-1">
                  <datepicker
                    v-model="toDate"
                    class="form-control"
                    inputFormat="dd-MM-yyyy"
                    placeholder="To Date"
                    typeable="true"
                    style="width: 100% !important" />
                </div>

                <div class="col-lg-3 col-sm-2 mt-1" style="margin-left: -40px">
                  <VueDatepicker
                    hide-input-icon
                    v-bind:clearable="false"
                    v-model="fromTime"
                    format="HH:mm:ss"
                    enable-seconds
                    textInput
                    time-picker
                    seconds-increment="1"
                    time-picker-inline
                    :is-24="true"
                    placeholder="From Time" />
                </div>
                <!-- time-picker -->
                <div class="col-lg-3 col-sm-2 mt-1" style="margin-left: -60px">
                  <VueDatepicker
                    hide-input-icon
                    v-bind:clearable="false"
                    v-model="toTime"
                    format="HH:mm:ss"
                    enable-seconds
                    textInput
                    time-picker
                    seconds-increment="1"
                    time-picker-inline
                    :is-24="true"
                    placeholder="To Time" />
                </div>
              </div>
              <div class="col-lg-5 col-sm-12 row mt-1 justify-content-end">
                <button
                  id="btn-search"
                  type="button"
                  v-on:click="getList()"
                  class="btn btn-primary mb-2">
                  Search
                </button>
                &nbsp;
                <button
                  type="button"
                  v-on:click="reset"
                  class="btn btn-secondary mb-2">
                  Reset
                </button>
                <button
                  v-if="this.$store.getters.getAccess?.is_tradeExport"
                  type="button"
                  class="btn btn-warning mb-2 ml-2"
                  v-on:click="getList('', '', false)">
                  Export
                </button>
                <button
                  v-if="this.$store.getters.getAccess?.is_tradeExport"
                  type="button"
                  class="btn btn-secondary mb-2 ml-2"
                  v-on:click="getList('', '', true)">
                  All Export
                </button>
                <button
                  v-if="this.$store.getters.getAccess?.is_tradeAdd"
                  id="btn-search"
                  type="button"
                  v-on:click="showTradePopup()"
                  class="btn btn-primary mb-2 ml-2">
                  Add Trade
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        class="row"
        style="padding-left: 50px; padding-right: 50px; margin-top: 5px">
        <div class="col-md-12 bg-light border-page">
          <div class="col-12 row">
            <div
              class="col-lg-6"
              style="
                font-weight: bold;
                margin-top: 10px;
                text-align: initial;
                font-size: 13px;
              ">
              Trade Book : {{ count }}
            </div>

            <div
              class="col-lg-6 mt-1"
              style="display: flex; justify-content: end">
              <button
                v-if="role == 'SUPER_MASTER' || role == 'MASTER'"
                type="button"
                style="
                  width: 100px !important;
                  margin-top: 0 !important;
                  font-size: 14px;
                  padding: 4px;
                "
                class="btn btn-info mb-2 mt-2"
                v-on:click="showJobbingModal()">
                Jobbing Trade
              </button>
              <button
                v-if="role == 'SUPER_MASTER' || role == 'MASTER'"
                type="button"
                style="
                  width: 100px !important;
                  margin-top: 0 !important;
                  font-size: 14px;
                  padding: 4px;
                "
                class="btn btn-warning mr-2 mb-2 ml-2 mt-2"
                v-on:click="showWrongTradeModal()">
                Wrong Trade
              </button>

              <button
                v-if="this.$store.getters.getAccess?.is_tradeDelete"
                v-on:click="deleteSelecOne()"
                class="btn btn-danger mr-2 mb-2"
                style="
                  width: 85px !important;
                  margin-top: 0 !important;
                  font-size: 14px;
                "
                id="delete-button"
                type="button">
                DELETE
              </button>

              <button
                v-on:click="revserseSelectedOne()"
                v-if="this.$store.getters.getAccess?.is_tradeReverse"
                class="btn mr-2 cursor-pointer mb-2"
                style="
                  width: 100px !important;
                  margin-top: 0 !important;
                  font-size: 14px;
                  background-color: darkorange;
                  padding: 8px;
                "
                id="delete-button"
                type="button">
                Reverse Trade
              </button>

              <Field
                type="text"
                class="form-control"
                name="ledger_balance"
                placeholder="SEARCH"
                v-model="keyword" />
            </div>

            <!-- v-if="role == 'SUPER_MASTER' && is_edit == 1" -->
          </div>
          <div class="sticky-table-header">
            <table
              id="table"
              style="margin-top: 16px"
              data-toggle="table"
              data-search="true"
              data-filter-control="true"
              data-toolbar="#toolbar"
              class="table table-sm text-center text-white table-bordered product-list table-hover">
              <thead>
                <tr>
                  <th
                    class="head"
                    style="width: 50px !important"
                    v-if="role == 'SUPER_MASTER' && is_edit == 1">
                    <div class="switchToggleRead" style="text-align: start">
                      <input
                        type="checkbox"
                        v-model="isChecked"
                        :true-value="true"
                        :false-value="false"
                        v-on:change="checkall(isChecked)" />
                      <!-- Is Delete -->
                    </div>
                  </th>
                  <th
                    class="head sorting"
                    id="id-device"
                    v-on:click="sorting('device', 'id-device')">
                    D
                  </th>
                  <th class="head">Sr No.</th>
                  <th
                    class="head sorting"
                    id="id-id"
                    v-on:click="sorting('id', 'id-id')">
                    Trade No
                  </th>

                  <th
                    class="head sorting"
                    id="id-order_type"
                    v-on:click="sorting('order_type', 'id-order_type')">
                    O/T
                  </th>
                  <th
                    class="head sorting"
                    id="id-created_at"
                    v-on:click="sorting('created_at', 'id-created_at')">
                    Trade Date
                  </th>
                  <th
                    class="head sorting"
                    id="id-Client"
                    v-on:click="sorting('id', 'id-Client')">
                    Client
                  </th>
                  <th class="head" id="id-Master">Master</th>
                  <th
                    class="head sorting"
                    id="id-trading_symbol"
                    v-on:click="sorting('trading_symbol', 'id-trading_symbol')">
                    Script
                  </th>
                  <th
                    class="head sorting"
                    id="id-order_action"
                    v-on:click="sorting('order_action', 'id-order_action')">
                    Type
                  </th>
                  <th class="head" id="id-eeee">I/O</th>
                  <th
                    class="head sorting"
                    id="id-type"
                    v-on:click="sorting('type', 'id-type')">
                    Trade Type
                  </th>
                  <th
                    class="head sorting"
                    id="id-lot"
                    v-on:click="sorting('lot', 'id-lot')">
                    Lot
                  </th>
                  <th
                    class="head sorting"
                    id="id-quantity"
                    v-on:click="sorting('quantity', 'id-quantity')">
                    Qty
                  </th>

                  <th
                    class="head sorting"
                    id="id-price"
                    v-on:click="sorting('price', 'id-price')">
                    Rate
                  </th>
                  <th
                    class="head sorting"
                    id="id-price2"
                    v-on:click="sorting('net_price', 'id-price2')">
                    Net Rate
                  </th>
                  <th
                    class="head sorting"
                    id="id-Athority"
                    v-on:click="sorting('id', 'id-Athority')">
                    User
                  </th>
                  <th
                    class="head sorting"
                    id="id-eeee"
                    v-on:click="sorting('ip', 'ip-eeee')">
                    IP
                  </th>

                  <!-- <th
                    class="head sorting"
                    id="id-created_at2"
                    v-on:click="sorting('created_at', 'id-created_at2')"
                  >
                    O-T 
                  </th> -->
                  <th
                    class="head sorting"
                    id="id-created_at2"
                    v-on:click="sorting('trade_date', 'id-created_at2')">
                    T-T
                  </th>
                  <th
                    v-if="this.$store.getters.getAccess?.is_tradeEdit"
                    class="head"
                    id="id-name">
                    Modify
                  </th>
                  <th
                    v-if="this.$store.getters.getAccess?.is_tradeDelete"
                    class="head"
                    id="id-name">
                    Cancel
                  </th>
                </tr>
              </thead>
              <tbody v-show="!loader">
                <tr v-for="(data, index) in datalist" :key="index">
                  <td
                    class="body"
                    v-if="role == 'SUPER_MASTER' && is_edit == 1">
                    <div class="switchToggleRead" style="text-align: start">
                      <input
                        :id="'index_' + data.id"
                        type="checkbox"
                        v-model="data.isChecked"
                        v-on:change="checkHandlling(data.isChecked)"
                        :true-value="true"
                        :false-value="false" />
                    </div>
                  </td>
                  <td
                    style="width: 50px"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    <i
                      v-if="data.device == 'WEB'"
                      class="fa fa-globe"
                      aria-hidden="true"></i>
                    <i
                      v-if="data.device == 'MOBILE'"
                      class="fa fa-mobile"
                      aria-hidden="true"></i>

                    <i
                      class="fa fa-user"
                      v-if="data.device == 'ADMIN'"
                      aria-hidden="true"></i>
                    <i
                      class="fa fa-user"
                      v-if="!data.device"
                      aria-hidden="true"></i>
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ index + 1 }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.id }}
                  </td>

                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.order_type }}
                  </td>
                  <td
                    v-if="data.isEdit == false"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{
                      data.created_at
                        ? $helperService.getDate(data.trade_date)
                        : ""
                    }}
                  </td>
                  <td
                    style="width: 200px !important"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    v-if="data.isEdit == true">
                    <div style="display: block ruby">
                      <VueDatepicker
                        hide-input-icon
                        v-bind:clearable="false"
                        v-model="data.trade_date"
                        format="dd/MM/yyyy hh:mm"
                        enable-seconds
                        textInput
                        seconds-increment="1"
                        time-picker-inline
                        :is-24="true"
                        placeholder="Select DateTime" />
                    </div>
                  </td>
                  <td
                    style="text-align: left"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{
                      data.user
                        ? $helperService.getUpperCase(
                            data.user?.name + "-" + data.user?.code
                          )
                        : ""
                    }}
                  </td>
                  <td
                    style="text-align: left"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.user ? getMasterName(data.user?.master_id) : "" }}
                  </td>
                  <td
                    style="text-align: left"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.trading_symbol ? data.trading_symbol : "" }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    :style="
                      data.order_action == 'BUY'
                        ? 'color: blue;'
                        : 'color: red;'
                    ">
                    {{
                      data.order_type == "MARKET"
                        ? data.order_action
                        : data.order_type == "INTRADAY"
                        ? "Intraday Close"
                        : data.order_action + " " + data.order_type
                    }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.new_type && data.new_type ? data.new_type : "-" }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.type ? $helperService.getupdated(data.type) : "-" }}
                    {{ data?.intraday_type ? data.intraday_type : "" }}
                    <!-- {{ data.order_type == "INTRADAY" ? ' (I-DAY)' : '' }} -->
                  </td>
                  <td
                    v-if="data.isEdit == false"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.lot }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    v-if="data.isEdit == true">
                    <div style="display: block ruby; width: 60px">
                      <Field
                        type="text"
                        class="form-control"
                        name="Lot"
                        style="
                          width: 60px;
                          height: 25px !important;
                          font-size: 14px;
                        "
                        v-model="data.lot"
                        v-on:keyup="
                          updateQuantity(
                            data.lot,
                            data.script.custum_script.lot_size,
                            index
                          )
                        "
                        placeholder="Lot"
                        v-on:keypress="
                          $helperService.allowDecimalValue($event)
                        " />
                    </div>
                  </td>
                  <td
                    v-if="data.isEdit == false"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.quantity }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    v-if="data.isEdit == true">
                    <div style="display: block ruby; width: 80px">
                      <Field
                        type="text"
                        class="form-control"
                        name="Qty"
                        style="
                          width: 80px;
                          height: 25px !important;
                          font-size: 14px;
                        "
                        :style="
                          data.exchange == 'MCX' ? 'cursor: no-drop;' : ''
                        "
                        v-model="data.quantity"
                        placeholder="Qty"
                        :disabled="data.exchange == 'MCX'"
                        v-on:keyup="
                          updateLotNew(
                            data.quantity,
                            data.script.custum_script.lot_size,
                            index
                          )
                        "
                        v-on:keypress="
                          $helperService.allowDecimalValue($event)
                        " />
                    </div>
                  </td>
                  <td
                    v-if="data.isEdit == false"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.price }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                    v-if="data.isEdit == true">
                    <div style="display: block ruby; width: 90px">
                      <Field
                        type="text"
                        style="
                          width: 90px;
                          height: 25px !important;
                          font-size: 14px;
                        "
                        class="form-control"
                        name="Price"
                        placeholder="Price"
                        v-model="data.price"
                        v-on:keypress="
                          $helperService.allowDecimalValue($event)
                        " />
                    </div>
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.net_price?.toFixed(2) }}
                  </td>

                  <td
                    style="text-align: left"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{
                      data.athority
                        ? $helperService.getUpperCase(data.athority?.code)
                        : ""
                    }}
                  </td>
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{ data.ip }}
                  </td>
                  <!-- <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    "
                  >
                   {{
                      data.created_at
                        ? $helperService.getDateTime(data.created_at)
                        : ""
                    }}
                  </td> -->
                  <td
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    {{
                      data.trade_date
                        ? $helperService.getDateTime(data.trade_date)
                        : ""
                    }}
                  </td>
                  <td
                    v-if="
                      role == 'SUPER_MASTER' &&
                      this.$store.getters.getAccess?.is_tradeEdit
                    "
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    <i
                      v-if="data.isEdit == false && data.Tradetype == 'TRADE'"
                      v-on:click="editRow(index)"
                      class="fa fa-pencil-square-o mr-2"
                      aria-hidden="true"
                      style="
                        cursor: pointer;
                        color: rgb(29, 68, 91);
                        font-size: 21px;
                      "></i>
                    <i
                      v-if="data.isEdit == true && data.Tradetype == 'TRADE'"
                      v-on:click="verify(index)"
                      class="fa fa-check mr-2"
                      aria-hidden="true"
                      style="
                        cursor: pointer;
                        color: green;
                        font-size: 21px;
                      "></i>
                    <i
                      v-if="data.isEdit == true && data.Tradetype == 'TRADE'"
                      v-on:click="closeIsEdit()"
                      class="fa fa-times mr-2"
                      aria-hidden="true"
                      style="cursor: pointer; color: red; font-size: 21px"></i>
                  </td>
                  <td
                    v-if="this.$store.getters.getAccess?.is_tradeDelete"
                    :class="
                      data.order_action == 'BUY' ? 'body-buy' : 'body-sell'
                    ">
                    <i
                      v-if="data.Tradetype == 'TRADE'"
                      v-on:click="showDeletePopup(data)"
                      class="fa fa-trash-o"
                      aria-hidden="true"
                      style="cursor: pointer; color: red; font-size: 21px"></i>
                  </td>
                </tr>
              </tbody>
            </table>
            <ErrorComponent @retry="getList()" ref="errorComponent" />
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      @remove="deleteRecord($event)"
      @get-list="getList()"
      ref="deleteItem" />
    <TextModal ref="split" width="75">
      <div class="modal-header pt-0">
        <h5 class="modal-title">ADD TRADE</h5>
        <button type="button" class="close" v-on:click="closeAddTrade()">
          <span aria-hidden="true" style="color: white">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="padding: 0 !important">
        <Form ref="splitForm" @submit="save()">
          <div class="col-lg-12 col-sm-12 row mb-3 mt-4">
            <div class="col-lg-3">
              <label class="col-form-label ml-1 mt-0"> Client </label>
              <Field
                type="text"
                name="user_id"
                placeholder="EXPIRY"
                rules="required: client"
                v-model="tradeobj.user_id"
                :validateOnInput="true"
                v-slot="{ field }"
                v-bind="field">
                <Multiselect
                  mode="single"
                  trackBy="new_name"
                  label="new_name"
                  valueProp="id"
                  class="register-select"
                  placeholder="CLIENT"
                  v-model="tradeobj.user_id"
                  :options="client_list"
                  searchable="true" />
                <ErrorMessage name="user_id" class="text-danger" />
              </Field>
            </div>

            <div class="col-lg-3">
              <label class="col-form-label ml-1 mt-0"> Segment </label>
              <Field
                type="text"
                name="exchange"
                placeholder="MARKET"
                rules="required: exchange"
                v-model="tradeobj.exchange"
                :validateOnInput="true"
                v-slot="{ field }"
                v-bind="field">
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="value"
                  class="register-select"
                  placeholder="MARKET"
                  v-model="tradeobj.exchange"
                  :options="exchanges"
                  searchable="true"
                  @select="getScriptName(tradeobj.exchange)" />
                <ErrorMessage name="exchange" class="text-danger" />
              </Field>
            </div>
            <div class="col-lg-3">
              <label class="col-form-label ml-1 mt-0"> Script Name </label>
              <Field
                type="text"
                name="instrument_token"
                placeholder="SCRIPT"
                rules="required: script"
                v-model="tradeobj.instrument_token"
                :validateOnInput="true"
                v-slot="{ field }"
                v-bind="field">
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="instrument_token"
                  class="register-select"
                  placeholder="SCRIPT"
                  v-model="tradeobj.instrument_token"
                  :options="scriptList"
                  @select="getScriptExpire(tradeobj.instrument_token)"
                  @change="emptyExpire()"
                  searchable="true" />
                <ErrorMessage name="instrument_token" class="text-danger" />
              </Field>
            </div>
            <div class="col-lg-3">
              <label class="col-form-label ml-1 mt-0"> Script Expiry </label>
              <Field
                type="text"
                name="expire_token"
                placeholder="EXPIRY"
                rules="required: expiry"
                v-model="tradeobj.expire_token"
                :validateOnInput="true"
                v-slot="{ field }"
                v-bind="field">
                <Multiselect
                  mode="single"
                  trackBy="expiry"
                  label="expiry"
                  valueProp="instrument_token"
                  class="register-select"
                  placeholder="EXPIRY"
                  v-model="tradeobj.expire_token"
                  :options="expireList"
                  searchable="true" />
                <ErrorMessage name="expire_token" class="text-danger" />
              </Field>
            </div>

            <div class="col-lg-3">
              <label class="col-form-label ml-1 mt-0"> Order Action </label>
              <Field
                type="text"
                name="order_action"
                placeholder="ORDER ACTION"
                rules="required: order action"
                v-model="tradeobj.order_action"
                :validateOnInput="true"
                v-slot="{ field }"
                v-bind="field">
                <Multiselect
                  mode="single"
                  trackBy="name"
                  label="name"
                  valueProp="value"
                  class="register-select"
                  placeholder="ORDER ACTION"
                  v-model="tradeobj.order_action"
                  :options="order_action"
                  searchable="true" />
                <ErrorMessage name="order_action" class="text-danger" />
              </Field>
            </div>

            <div class="col-lg-3">
              <label class="col-form-label ml-1 mt-0">Lot</label>

              <Field
                type="text"
                class="form-control"
                name="lot"
                placeholder="Lot"
                v-model="tradeobj.lot"
                rules="required: lot"
                :validateOnInput="true"
                v-on:keyup="updateQuantityNew(tradeobj.lot)"
                v-on:keypress="$helperService.allowDecimalValue($event)" />
              <ErrorMessage name="lot" class="text-danger" />
            </div>
            <div class="col-lg-3">
              <label class="col-form-label ml-1 mt-0">QTY</label>
              <Field
                type="text"
                class="form-control"
                name="QTY"
                placeholder="QTY"
                :style="tradeobj.exchange == 'MCX' ? 'cursor: no-drop;' : ''"
                :disabled="tradeobj.exchange == 'MCX'"
                v-model="tradeobj.quantity"
                rules="required: quantity"
                :validateOnInput="true"
                v-on:keyup="updateLot(tradeobj.lot)"
                v-on:keypress="$helperService.allowDecimalValue($event)" />
              <ErrorMessage name="QTY" class="text-danger" />
            </div>

            <div class="col-lg-3" style="display: none">
              <label class="col-form-label ml-1 mt-0">Price</label>

              <Field
                type="text"
                class="form-control"
                name="tick_size"
                placeholder="tick_size"
                v-model="tradeobj.tick_size" />
            </div>
            <div class="col-lg-3">
              <label class="col-form-label ml-1 mt-0">Price</label>

              <Field
                type="text"
                class="form-control"
                name="price"
                placeholder="Price"
                v-model="tradeobj.price"
                rules="required: price|tickSize:@tick_size"
                :validateOnInput="true"
                v-on:keypress="$helperService.allowDecimalValue($event)" />
              <ErrorMessage name="price" class="text-danger" />
            </div>
          </div>
          <div class="pb-0 text-center">
            <button class="save-btn green-btn mr-2" id="split-button">
              Submit
            </button>
            <button
              class="save-btn"
              style="background-color: grey"
              v-on:click="closeAddTrade()">
              Close
            </button>
          </div>
        </Form>

        <table
          id="table"
          style="margin-top: 16px; margin: 2%; width: 96%"
          data-toggle="table"
          data-search="true"
          data-filter-control="true"
          data-toolbar="#toolbar"
          class="table table-sm text-center table-bordered product-list table-hover">
          <thead>
            <tr>
              <th class="head">Client</th>
              <th class="head">Segment</th>
              <th class="head">Script Name</th>
              <th class="head">Script Expiry</th>
              <th class="head">Order Action</th>
              <th class="head">Lot</th>
              <th class="head">Quantity</th>
              <th class="head">Price</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in this.tradeList" :key="index">
              <td>
                {{ data.client }}
              </td>
              <td>
                {{ data.exchange == "NFO" ? "NSE" : data.exchange }}
              </td>
              <td>
                {{ data.name }}
              </td>
              <td>
                {{ data.expiry }}
              </td>
              <td>
                {{ data.order_action }}
              </td>
              <td>
                {{ data.lot }}
              </td>
              <td>
                {{ data.quantity }}
              </td>
              <td>
                {{ data.price }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </TextModal>

    <JobbingModal ref="JobbingModal" />
    <WrongTradeModal ref="WrongTradeModal" />
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import moment from "moment";
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "@/components/TextModal.vue";
import Multiselect from "@vueform/multiselect";
import VueDatepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import DeleteModal from "@/components/DeleteModal";
import swal from "sweetalert2";
import Datepicker from "vue3-datepicker";
import JobbingModal from "../../components/JobbingModal.vue";
import WrongTradeModal from "../../components/WrongTradeModal.vue";

export default {
  components: {
    Field,
    Form,
    ErrorMessage,
    TextModal,
    ErrorComponent,
    DeleteModal,
    Multiselect,
    VueDatepicker,
    Datepicker,
    JobbingModal,
    WrongTradeModal,
  },
  name: "Profile",
  data() {
    return {
      loader: true,
      role: this.$store.getters.getUser?.role,
      is_edit: localStorage.getItem("is_edit"),
      list: [],
      fromDate: new Date(),
      toDate: new Date(),
      fromTime: null,
      toTime: null,
      isChecked: false,
      keyword: "",
      tradeList: [],
      authority_id: "",
      broker_id: "",
      master_id: "",
      exchange: "",
      tradeobj: {
        expire_token: "",
      },
      type: "",
      lot_size: "",
      searchObj: {},
      client_list: [],
      master_list: [],
      broker_list: [],
      script_list: [],
      expireList: [],
      scriptList: [],
      exchanges: [
        {
          name: "NSE",
          value: "NFO",
        },
        {
          name: "MCX",
          value: "MCX",
        },
        {
          name: "COMEX",
          value: "COMEX",
        },
      ],
      order_action: [
        {
          name: "BUY",
          value: "BUY",
        },
        {
          name: "SELL",
          value: "SELL",
        },
      ],
      fileHeader:
        "segment,clientcode,scriptname,ex_date,ce_pe,strike,buysell,lot,qty,rate,trade_date,trade_time,order_no,trade_no",
      excel_data: [],
      all_excel_data: [],
      count: 0,
      tradeType: [
        {
          name: "BUY",
          value: "BUY",
        },
        {
          name: "SELL",
          value: "SELL",
        },
      ],
    };
  },
  computed: {
    datalist() {
      return this.keyword
        ? this.list.filter((e) => {
            return (
              e.user?.name.toLowerCase().indexOf(this.keyword.toLowerCase()) >
                -1 ||
              e.user?.code.toLowerCase().indexOf(this.keyword.toLowerCase()) >
                -1 ||
              e.trading_symbol
                .toLowerCase()
                .indexOf(this.keyword.toLowerCase()) > -1
            );
          })
        : this.list;
    },
  },
  mounted() {
    if (localStorage.getItem("temp_token")) {
      this.is_edit = 1;
    }

    this.getList();
    this.getClientList();
    this.getBrokerList();
    this.getMasterList();
  },
  methods: {
    showWrongTradeModal() {
      this.$refs.WrongTradeModal.showModal(this.datalist);
    },
    showJobbingModal() {
      this.$refs.JobbingModal.showModal(this.searchObj);
    },
    getClientList() {
      this.$api
        .getAPI({
          _action: "/client-list",
        })
        .then((res) => {
          this.client_list = [];
          for (var i in res) {
            this.client_list.push({
              ...res[i],
              new_name: res[i].name + " (" + res[i].code + ")",
            });
          }
        })
        .catch(() => {});
    },
    getMasterList() {
      this.$api
        .getAPI({
          _action: "/master-list",
        })
        .then((res) => {
          this.master_list = res;
          for (var i in this.master_list) {
            this.master_list[i].new_name =
              this.master_list[i].name + " (" + this.master_list[i].code + ")";

            // if (this.details.user_id == this.master_list[i].id) {
            //   this.user_detail = this.master_list[i];
            // }
          }
        })
        .catch(() => {});
    },
    getScriptExpire(instrument_token) {
      this.$api
        .getAPI({
          _action: "/expire-list/" + instrument_token,
        })
        .then((res) => {
          this.expireList = res;
          for (var i in this.expireList) {
            if (this.expireList[i].expiry) {
              this.expireList[i].expiry = moment(
                this.expireList[i].expiry
              ).format("DD-MM-YYYY");
            }
          }
          if (this.expireList.length > 0) {
            this.tradeobj.expire_token = this.expireList[0].instrument_token;
            this.lot_size = this.expireList[0].lot_size;
            this.tradeobj.tick_size = this.expireList[0].tick_size;
            if (this.expireList[0].lot_size == 1) {
              this.tradeobj.lot = this.expireList[0].lot_size;
              this.tradeobj.quantity = this.expireList[0].lot_size;
            }
            if (this.expireList[0].lot_size > 1) {
              this.tradeobj.lot = 1;
              this.tradeobj.quantity = this.expireList[0].lot_size;
            }
          }
        })
        .catch(() => {});
    },
    emptyExpire() {
      this.tradeobj.expire_token = "";
    },
    updateQuantityNew(lot) {
      this.tradeobj.quantity = lot * this.lot_size;
    },
    updateLot() {
      this.tradeobj.lot = this.$helperService.getFormattedPrice(
        this.tradeobj.quantity / this.lot_size
      );
    },
    getScriptName(exchange) {
      if (this.tradeobj.instrument_token) {
        this.tradeobj.instrument_token = "";
      }
      if (this.tradeobj.expire_token) {
        this.tradeobj.expire_token = "";
      }
      this.expireList = [];
      this.scriptList = [];
      if (exchange) {
        this.$api
          .getAPI({
            _action: "/script-list/" + exchange,
          })
          .then((res) => {
            this.scriptList = res;
          })
          .catch(() => {});
      }
    },
    showTradePopup() {
      this.tradeobj = {};
      this.$refs.split.showModal();
      setTimeout(() => {
        this.$refs.splitForm.resetForm();
      }, 100);
    },
    getList(orderBy, sortBy, new_type) {
      this.$refs.errorComponent.updateFormLoader(true);
      this.list = [];
      var searchObj = {};
      searchObj.day = "TODAY";
      if (this.fromTime) {
        searchObj.fromTime = moment(this.fromTime).format("HH:mm:ss");
      }
      if (this.toTime) {
        searchObj.toTime = moment(this.toTime).format("HH:mm:ss");
      }
      if (this.fromDate) {
        if (this.fromTime) {
          searchObj.from_date =
            moment(this.fromDate).format("YYYY-MM-DD") +
            " " +
            moment(this.fromTime).format("HH:mm:ss");
        } else {
          searchObj.from_date =
            moment(this.fromDate).format("YYYY-MM-DD") + " 00:00:00";
        }

        searchObj.day = "";
      }
      if (this.toDate) {
        if (this.toTime) {
          searchObj.to_date =
            moment(this.toDate).format("YYYY-MM-DD") +
            " " +
            moment(this.toTime).format("HH:mm:ss");
        } else {
          searchObj.to_date =
            moment(this.toDate).format("YYYY-MM-DD") + " 23:59:59";
        }
        searchObj.day = "";
      }

      if (this.trade_type) {
        searchObj.trade_action_type = this.trade_type;
      }
      if (this.name) {
        searchObj.name = this.name;
      }
      if (this.keyword) {
        searchObj.keyword = this.keyword;
      }
      if (this.authority_id) {
        searchObj.authority_id = this.authority_id;
      }
      if (this.broker_id) {
        searchObj.broker_id = this.broker_id;
      }
      if (this.master_id) {
        searchObj.master_id = this.master_id;
      }
      if (this.user_id) {
        searchObj.user_id = this.user_id;
      }
      if (this.exchange) {
        searchObj.exchange = this.exchange;
      }
      if (orderBy) {
        searchObj.order_by = orderBy;
      } else {
        searchObj.order_by = "desc";
      }
      if (sortBy) {
        searchObj.sort_by = sortBy;
      } else {
        searchObj.sort_by = "trade_date";
      }
      searchObj.trade_status = "EXECUTED";
      ("");
      this.searchObj = searchObj;
      this.$api
        .getAPI({
          _action: "/trade-list",
          _body: searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res;
          for (var i in this.list) {
            var type = this.list[i].type.split(",");
            this.list[i].new_type = type[0] ? type[0] : "";
            this.list[i].type = type[1] ? type[1] : "";
            this.list[i].intraday_type = type[2] ? type[2] : "";
            this.list[i].isChecked = false;
            this.list[i].isEdit = false;
            this.list[i].lot = this.$helperService.getFormattedPrice(
              this.list[i].lot
            );
            this.list[i].price = this.$helperService.getFormattedPrice(
              this.list[i].price
            );
            this.list[i].quantity = this.$helperService.getFormattedPrice(
              this.list[i].quantity
            );
          }
          this.count = this.list.length;
          this.excel_data = [];
          this.all_excel_data = [];
          if (res.length > 0) {
            for (var j in res) {
              var year = moment(new Date()).format("YYYY");
              if (res[j].script && res[j].script.expiry) {
                year = moment(res[j].script.expiry, "YYYY-MM-DD").format(
                  "YYYY"
                );
              }

              console.log(res[j].trading_symbol.split(" ")[1]);
              var obj = "";
              obj += res[j].exchange
                ? res[j].exchange
                    .replace("MCX", "MCXFUT")
                    .replace("NFO", "NSEFUT")
                : "";
              obj += ",";
              obj += res[j].user ? res[j].user.name : "";
              obj += "(" + (res[j].user ? res[j].user.code : "") + ")";
              obj += ",";
              obj += res[j].trading_symbol
                ? res[j].trading_symbol.split(" ")[0]
                : "";
              obj += ",";
              obj +=
                res[j].trading_symbol && res[j].trading_symbol.split(" ")[1]
                  ? moment(
                      res[j].trading_symbol.split(" ")[1] + year,
                      "DDMMMYYYY"
                    )
                      .format("DD-MMM-YY")
                      .toLocaleUpperCase()
                  : "";
              obj += ",";
              obj += ",";
              obj += ",";
              obj += res[j].order_action;
              obj += ",";
              obj += res[j].lot;
              obj += ",";
              obj += res[j].quantity;
              obj += ",";
              obj += res[j].price;
              obj += ",";
              obj += this.$helperService.getCustomDateYearNew(
                res[j].trade_date
              );
              obj += ",";
              obj += this.$helperService.getTime(res[j].trade_date);
              obj += ",";
              obj += res[j].id;
              obj += ",";
              obj += res[j].id;
              this.all_excel_data.push(obj);
              if (
                res[j].type == "NORMAL" ||
                res[j].type == "DVD" ||
                res[j].type == "SPT"
              ) {
                this.excel_data.push(obj);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              { list: this.list },
              "No Data available"
            );
            this.$refs.errorComponent.updateFormLoader(false);
          }
          if (new_type == true || new_type == false) {
            this.download(new_type);
          }
        })

        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    getScriptList(exchange, type) {
      var searchObj = {};
      if (this.exchange) {
        searchObj.exchange = exchange;
      }
      this.$api
        .getAPI({
          _action: "/script-list-by-exchange",
          _body: searchObj,
        })
        .then((res) => {
          this.script_list = res;
          if (!type) {
            this.filterData();
          }
        })
        .catch(() => {});
    },

    checkall(isChecked) {
      for (var k in this.list) {
        if (isChecked) {
          this.list[k].isChecked = true;
        } else if (!isChecked) {
          this.list[k].isChecked = false;
        }
      }
    },
    checkHandlling(isChecked) {
      if (!isChecked) {
        this.isChecked = false;
      } else if (isChecked) {
        var count = -1;
        for (var k in this.list) {
          if (this.list[k].isChecked == false) {
            count = count + 1;
          }
        }
        console.log("object", count);
        if (count == 0) {
          this.isChecked = true;
        }
      }
    },
    save() {
      if (this.tradeobj.expire_token) {
        for (var i in this.expireList) {
          if (
            this.tradeobj.expire_token == this.expireList[i].instrument_token
          ) {
            this.tradeobj.trading_symbol =
              this.expireList[i].name +
              " " +
              this.$helperService.getCustomDateNew2(this.expireList[i].expiry);
          }
        }
      }

      this.$api
        .getAPI({
          _action: "/script-detail/" + this.tradeobj.expire_token,
        })
        .then((res) => {
          if (res.result) {
            if (
              res.result.h > this.tradeobj.price &&
              res.result.l < this.tradeobj.price
            ) {
              this.addTrade();
            } else {
              swal
                .fire({
                  title: "Are you sure?",
                  text:
                    "Your price is not in low (" +
                    res.result.l +
                    ") and high (" +
                    res.result.h +
                    ") limit ",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Add",
                  customClass: {
                    confirmButton: "btn btn-blue btn-fill",
                    cancelButton: "btn btn-danger btn-fill",
                  },
                })
                .then(async (value) => {
                  value.isConfirmed && this.addTrade();
                });
            }
          } else {
            this.$notify({
              title: "Error",
              type: "error",
              text: "Please try after sometime",
            });
          }
        })
        .catch(() => {});
    },
    addTrade() {
      if (this.tradeobj.user_id) {
        for (var client of this.client_list) {
          if (client.id == this.tradeobj.user_id) {
            this.tradeobj.client = client.new_name;
          }
        }
      }

      if (this.tradeobj.expire_token) {
        for (var i in this.expireList) {
          if (
            this.tradeobj.expire_token == this.expireList[i].instrument_token
          ) {
            this.tradeobj.expiry = this.expireList[i].expiry;
            this.tradeobj.name = this.expireList[i].name;
          }
        }
      }

      this.$api
        .postAPI({
          _action: "/add-mannual-trade",
          _body: this.tradeobj,
        })
        .then((res) => {
          if (res) {
            this.tradeList.push(this.tradeobj);
            this.tradeobj = {
              ...this.tradeobj,
              lot: "",
              quantity: "",
              price: "",
              order_action: "",
            };

            this.$notify({
              title: "Success",
              type: "success",
              text: res.message,
            });

            this.$refs.errorComponent.updateFormLoader(false);
            // this.$refs.split.closeModal();
          }
          // this.getList();
        })
        .catch(() => {
          this.$refs.errorComponent.updateFormLoader(false);
        });
    },
    closeAddTrade() {
      this.$refs.split.closeModal();
      this.getList();
    },
    deleteSelecOne() {
      this.$refs.errorComponent.updateFormLoader(true);
      var array = [];
      for (var i in this.list) {
        if (this.list[i].isChecked) {
          array.push(this.list[i].id);
        }
      }
      var obj = {};
      obj.ids = array;

      if (array.length > 0) {
        this.$api
          .postAPI({
            _action: "/delete-trade",
            _body: obj,
          })
          .then((res) => {
            if (res) {
              this.$notify({
                title: "Success",
                type: "success",
                text: res.message,
              });
              this.$refs.errorComponent.updateFormLoader(false);
              this.isChecked = false;
            }
            this.getList();
          })
          .catch(() => {
            this.$refs.errorComponent.updateFormLoader(false);
          });
      } else {
        this.$notify({
          type: "error",
          text: "Select atleast on setting.",
        });
        this.$refs.errorComponent.updateFormLoader(false);
      }
    },
    revserseSelectedOne() {
      this.$refs.errorComponent.updateFormLoader(true);
      var array = [];
      for (var i in this.list) {
        if (this.list[i].isChecked) {
          array.push(this.list[i].id);
        }
      }
      var obj = {};
      obj.ids = array;

      if (array.length > 0) {
        this.$api
          .postAPI({
            _action: "/reverse-trade",
            _body: obj,
          })
          .then((res) => {
            if (res) {
              this.$notify({
                title: "Success",
                type: "success",
                text: res.message,
              });
              this.$refs.errorComponent.updateFormLoader(false);
              this.isChecked = false;
            }
            this.getList();
          })
          .catch(() => {
            this.$refs.errorComponent.updateFormLoader(false);
          });
      } else {
        this.$notify({
          type: "error",
          text: "Select atleast on setting.",
        });
        this.$refs.errorComponent.updateFormLoader(false);
      }
    },
    getBrokerList() {
      this.$api
        .getAPI({
          _action: "/broker-list",
        })
        .then((res) => {
          this.broker_list = res;
          for (var i in this.broker_list) {
            this.broker_list[i].new_name =
              this.broker_list[i].name + "(" + this.broker_list[i].code + ")";
          }
        })
        .catch(() => {});
    },
    async fetchData() {
      return this.excel_data;
    },
    async fetchData2() {
      return this.all_excel_data;
    },
    startDownload() {
      console.log("d");
    },
    finishDownload() {
      console.log("d");
    },
    editRow(index) {
      for (var i in this.list) {
        if (i == index) {
          this.list[i].isEdit = true;
        } else {
          this.list[i].isEdit = false;
        }
      }
    },

    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(obj.order_by, obj.sort_by);
    },
    closeIsEdit() {
      for (var i in this.list) {
        this.list[i].isEdit = false;
      }
    },
    updateQuantity(lot, lot_size, index) {
      var qty = lot * lot_size;
      for (var i in this.list) {
        if (i == index) {
          this.list[i].quantity = qty;
        }
      }
    },
    updateLotNew(quantity, lot_size, index) {
      for (var i in this.list) {
        if (i == index) {
          this.list[i].lot = this.$helperService.getFormattedPrice(
            quantity / lot_size
          );
        }
      }
    },
    verify(index) {
      var obj = {};
      for (var i in this.list) {
        if (index == i) {
          obj = { ...this.list[i] };
        }
      }

      if (!obj || !obj.quantity || obj.quantity <= 0) {
        this.$notify({
          title: "Error",
          type: "error",
          text: "Please enter correct quantity",
        });

        return;
      }

      this.$api
        .getAPI({
          _action: "/script-detail/" + obj.instrument_token,
        })
        .then((res) => {
          if (res.result) {
            if (res.result.h > obj.price && res.result.l < obj.price) {
              this.updateRow(index);
            } else {
              swal
                .fire({
                  title: "Are you sure?",
                  text:
                    "Your price is not in low (" +
                    res.result.l +
                    ") and high (" +
                    res.result.h +
                    ") limit ",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Update",
                  customClass: {
                    confirmButton: "btn btn-blue btn-fill",
                    cancelButton: "btn btn-danger btn-fill",
                  },
                })
                .then(async (value) => {
                  value.isConfirmed && this.updateRow(index);
                });
            }
          } else {
            this.$notify({
              title: "Error",
              type: "error",
              text: "Please try after sometime",
            });
          }
        })
        .catch(() => {});
    },
    updateRow(index) {
      var obj = {};
      for (var i in this.list) {
        if (index == i) {
          this.list[i].quantity = this.$helperService.getFormattedPrice(
            this.list[i].quantity
          );
          this.list[i].lot = this.$helperService.getFormattedPrice(
            this.list[i].lot
          );
          this.list[i].price = this.$helperService.getFormattedPrice(
            this.list[i].price
          );

          this.list[i].isEdit = false;
          obj = { ...this.list[i] };
          obj.trade_date = moment(this.list[i].trade_date).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        }
      }

      if (!obj || !obj.quantity || obj.quantity <= 0) {
        this.$notify({
          title: "Error",
          type: "error",
          text: "Please enter correct quantity",
        });

        return;
      }

      this.$api
        .postAPI({
          _action: "/update-trade",
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            title: "Success",
            type: "success",
            text: res.message,
          });
          this.getList();
        })
        .catch(() => {
          this.getList();
        });
    },

    download(isAll) {
      let filename = "Trades-Book.csv";
      let text = this.fileHeader;
      if (isAll) {
        for (var i in this.all_excel_data) {
          text += "\n";
          text += this.all_excel_data[i];
        }
      } else {
        for (var j in this.excel_data) {
          text += "\n";
          text += this.excel_data[j];
        }
      }

      let blob = new Blob([text], { type: "text/csv" });
      let link = document.createElement("a");
      link.download = filename;
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      }, 100);
    },
    reset() {
      this.fromDate = "";
      this.toDate = "";
      this.getList();
    },
    showDeletePopup(data) {
      this.$refs.deleteItem.showModal(
        "Delete Order",
        "Are you sure  want to delete order",
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$refs.deleteItem.deleteRecord("/trade/" + detail.id);
    },
    getMasterName(id) {
      for (var i in this.master_list) {
        if (this.master_list[i].id == id) {
          return this.master_list[i].code.toUpperCase();
        }
      }

      return "";
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: white !important;
  max-height: 30px !important;
  min-height: 0px !important;
}

.multiselect-tag {
  background: #9cc338 !important;
}

.option-icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  margin-right: 10px;
}
</style>
